import { Container } from '@abclinic/ui';
import React from 'react';
import { Equipment } from './components/Equipment';
import { useEquipmentData } from './hooks/useEquipmentData';

export const EquipmentsSection = () => {
  const equipments = useEquipmentData();

  return (
    <section>
      <Container>
        <div className="flex gap-y-28 md:gap-y-40 py-10  items-center flex-col justify-center overflow-hidden">
          {equipments.map((eq, index) => (
            <Equipment
              key={index}
              name={eq.name}
              shortDescription={eq.shortDescription}
              image={eq.image}
              isEven={index % 2 === 0}
            />
          ))}
        </div>
      </Container>
    </section>
  );
};
