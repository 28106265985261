import React from 'react';

export interface CardProps {
  className?: string;
  children: React.ReactNode;
}

export const Card = (props: CardProps) => {
  const { className = '', children } = props;
  return (
    <article
      className={`rounded-2xl bg-white drop-shadow-lg  border border-slate-50 ${className}`}
    >
      {children}
    </article>
  );
};
