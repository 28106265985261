import React from 'react';
import { Control, useController } from 'react-hook-form';

export interface CheckboxProps {
  label: React.ReactNode;
  name: string;
  control: Control;
  rules?: any;
  className?: string;
}

export const Checkbox = (props: CheckboxProps) => {
  const { label, name, control, rules, className } = props;

  const {
    field: { onChange, onBlur, value, ref },
    fieldState: { invalid, isTouched, isDirty, error },
    formState: { touchedFields, dirtyFields },
  } = useController({
    name,
    control,
    defaultValue: '',
    rules: rules,
  });

  return (
    <div className={`flex ${className} flex-col `}>
      <div className="flex items-center">
        <input
          id={name}
          type="checkbox"
          onChange={onChange} // send value to hook form
          onBlur={onBlur} // notify when input is touched/blur
          name={name}
          value={value}
          ref={ref}
          className="w-4 h-4 rounded text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600"
        />
        <label htmlFor={name} className="ml-4 text-sm ">
          {label}
        </label>
      </div>
      {error && (
        <div className="text-xs text-red-300 mt-2">{error['message']}</div>
      )}
    </div>
  );
};
