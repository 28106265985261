import { KnowledgeShareItem } from '@abclinic/ui';
import { graphql, useStaticQuery } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { generateKnowledgeBaseArticleRoute } from '../../../../lib/routes';

export const useKnowledgeShareItems = (): KnowledgeShareItem[] => {
  const { allStrapiKnowledgeShareArticle } = useStaticQuery(graphql`
    query KnowledgeShareItemsQuery {
      allStrapiKnowledgeShareArticle {
        nodes {
          title
          slug
          alternativeTitle
          excerpt
          image {
            localFile {
              childImageSharp {
                gatsbyImageData(quality: 30, placeholder: BLURRED)
              }
            }
          }
        }
      }
    }
  `);

  return allStrapiKnowledgeShareArticle.nodes.map((item: any) => {
    const image = getImage(
      item.image.localFile.childImageSharp.gatsbyImageData
    );
    return {
      ...item,
      link: generateKnowledgeBaseArticleRoute(item.slug),
      perex: item.excerpt,
      image: (
        <GatsbyImage
          className="max-h-64 w-full md:h-full"
          imgClassName="rounded-t-xl overflow-hidden"
          image={image}
          alt={item.title}
        />
      ),
    };
  });
};
