import React from 'react';
import { ContactForm } from './components/ContactForm';

export interface ContactUsFormProps {
  className?: string;
  formButtonPosition?: 'left' | 'center' | 'right';
}

export const ContactUsForm = (props: ContactUsFormProps) => {
  const { className, formButtonPosition } = props;
  return (
    <section {...props}>
      <ContactForm buttonPosition={formButtonPosition} />
    </section>
  );
};
