import { Container } from '@abclinic/ui';
import { getImage, IGatsbyImageData } from 'gatsby-plugin-image';
import React from 'react';
import { StringIfPlural } from 'react-i18next';
import { HomeTestimonialCard } from './components/HomeTestimonialCard/HomeTestimonialCard';

export interface HomeTestimonialsProps {
  className?: string;
  title: string;
  review: string;
  patientName: string;
  patientOccupation: string;
  content?: string;
  button?: {
    label: string;
    href: string;
  };
  image: IGatsbyImageData;
}

export const HomeTestimonials = (props: HomeTestimonialsProps) => {
  const {
    className = '',
    title,
    review,
    patientName,
    patientOccupation,
    button,
    image,
  } = props;
  return (
    <section className="section--home-testimonials">
      <Container className="max-w-8xl">
        {/* <h3 className="text-text opacity-[13%] text-center font-bold text-lg">
          Pacienti o nás povedali
        </h3> */}
        <HomeTestimonialCard
          title={title}
          content={review}
          patientName={patientName}
          patientPosition={patientOccupation}
          buttonTitle={button?.label}
          buttonHref={button?.href}
          image={image}
        />
      </Container>
    </section>
  );
};
