import { PageSectionSpacer, StrapiSeo } from '@abclinic/ui';
import { graphql } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import React from 'react';
import { HomeBlogSection } from '../components/Home/BlogSection/HomeBlogSection';
import { EyesDisordesArticlesSection } from '../components/Home/EyesDisordesArticlesSection/EyesDisordesArticlesSection';
import { HomeHeroSection } from '../components/Home/Hero/HomeHeroSection';
import { HomeNotices } from '../components/Home/HomeNotices/HomeNotices';
import { HomeQuickNavigationSection } from '../components/Home/QuickNavigation/HomeQuickNavigationSection';
import { TechnologiesSection } from '../components/Home/TechnologiesSection/TechnologiesSection';
import { HomeTestimonials } from '../components/Home/Testimonials/HomeTestimonials';
import { Layout } from '../components/Layout/Layout';
import { ArticleBannerSection } from '../components/Shared/components/ArticleBannerSection/ArticleBannerSection';
import { generateArticleRoute } from '../lib/routes';
import { getFirstNWords } from '../lib/utils/getFirstNWords';

export interface IndexPageProps {
  data: any;
  children?: React.ReactNode | React.ReactNodeArray;
}

export const query = graphql`
  query IndexPageQuery($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    strapiHomePage {
      seo {
        ...SeoFragment
      }
      testimonialCard {
        review
        title
        patientName
        patientOccupation
        button {
          href
          label
        }
        image {
          localFile {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED)
            }
          }
        }
      }
    }
    strapiFeaturedArticlesSection(locale: { eq: $language }) {
      ...FeaturedArticlesSectionFragment
    }
    strapiArticleBannerSection(locale: { eq: $language }) {
      ...ArticleBannerSectionFragment
    }
  }
`;

export const IndexPage = (props: IndexPageProps) => {
  const { data, ...restOfProps } = props;

  return (
    <Layout {...restOfProps} headerBtnType="secondary">
      <StrapiSeo seo={data?.strapiHomePage?.seo} />
      <PageSectionSpacer>
        <HomeHeroSection />
        <HomeNotices />
        <HomeQuickNavigationSection />
        <EyesDisordesArticlesSection />

        <ArticleBannerSection
          title={data?.strapiArticleBannerSection?.article?.article.title}
          content={data?.strapiArticleBannerSection?.article.article.excerpt}
          button={{
            label: data?.strapiArticleBannerSection?.buttonLabel,
            href: generateArticleRoute(
              data?.strapiArticleBannerSection?.article?.article.slug
            ),
          }}
          image={
            <GatsbyImage
              className="w-full h-full"
              image={getImage(
                data.strapiArticleBannerSection?.article.article.image.localFile
                  .childImageSharp.gatsbyImageData
              )}
              imgClassName="rounded-xl overflow-hidden"
              alt={
                data?.strapiArticleBannerSection?.article?.article?.image
                  .alternativeText
              }
            />
          }
          {...props}
        />

        <TechnologiesSection />
        <HomeTestimonials {...data?.strapiHomePage?.testimonialCard} />
        {/* Blog */}
        <HomeBlogSection
          header={data.strapiFeaturedArticlesSection.header}
          articles={data.strapiFeaturedArticlesSection?.featured?.articles.map(
            (article: any) => ({
              author: article.author?.name,
              title: article.title,
              perex: getFirstNWords(article.excerpt),
              route: generateArticleRoute(article.slug),
              slug: article.slug,
              image: (
                <GatsbyImage
                  className="w-full h-full"
                  image={getImage(
                    article?.image?.localFile?.childImageSharp.gatsbyImageData
                  )}
                  imgClassName="rounded-xl overflow-hidden"
                  alt={article.image.alternativeText}
                />
              ),
            })
          )}
        />
      </PageSectionSpacer>
    </Layout>
  );
};

export default IndexPage;
