import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { generateArticleRoute } from '../../routes';
import { getFirstNWords } from '../../utils/getFirstNWords';
import { ArticleCardFragment } from './model/article.model';

export const mapArticleCardFragmentToCardProps = (
  article: ArticleCardFragment
) => ({
  author: '',
  title: article.title,
  perex: getFirstNWords(article.excerpt, 28),
  route: generateArticleRoute(article.slug),
  slug: article.slug,
  image: (
    <GatsbyImage
      className="w-full h-full"
      image={getImage(article.image.localFile.childImageSharp.gatsbyImageData)}
      imgClassName="rounded-xl overflow-hidden"
      alt={article.image.alternativeText}
    />
  ),
});
