import { StaticImage } from 'gatsby-plugin-image';
import React from 'react';
import { DisorderArticleItem } from './DisorderArticleItem';

export interface DisorderArticleItemsProps {
  className?: string;
}

export const DisorderArticleItems = (props: DisorderArticleItemsProps) => {
  const { className } = props;
  return (
    <div className={` ${className} flex flex-col items-center gap-y-10 `}>
      {/* TOP LEFT ITEM  */}
      <DisorderArticleItem
        image={
          <StaticImage
            alt="Astigmatizmus"
            src={'./../assets/astigmatizmus.png'}
            className="w-full h-full rounded-xl overflow-hidden"
            placeholder="blurred"
            quality={35}
            imgClassName="rounded-xl overflow-hidden"
          />
        }
        title={'Astigmatizmus'}
        description={
          'Príčinou je nerovnomerné zakrivenie rohovky. Astigmatici tak vidia časť obrazu rozmazane či zahmlene.'
        }
        textClassName={{
          title: 'text-[17px]',
          wrapper: 'mx-3 sm:mx-6 mx-[21px] mb-[26px]',
        }}
        itemClassName={'sm:z-30 xl:z-0 lg:w-[254px] lg:h-[309px] top-0 left-0'}
        imgClassName={'h-48 sm:h-[171px]'}
      />
      {/* BOTTOM LEFT ITEM  */}
      <DisorderArticleItem
        title={'Ďalekozrakosť'}
        image={
          <StaticImage
            alt="Ďalekozrakosť"
            src={'./../assets/dalekozrkost.png'}
            className="w-full h-full"
            placeholder="blurred"
            quality={35}
            imgClassName="rounded-xl"
          />
        }
        description={'Prejavuje sa neostrým videním blízkych predmetov.'}
        textClassName={{
          title: 'text-[18px]',
          wrapper: 'mx-3 sm:mx-6 mx-[22px]  mb-[27px]',
        }}
        itemClassName={
          'sm:z-10 lg:z-0 xl:z-0  lg:w-[272px] lg:h-[296px] sm:mt-20 bottom-0 left-0'
        }
        imgClassName={'h-48 sm:h-[184px]'}
      />
      {/* BIGGER RIGHT ITEM  */}
      <DisorderArticleItem
        title={'Krátkozrakosť'}
        image={
          <StaticImage
            alt="Krátkozrakosť"
            src={'./../assets/elena-taranenko-hCUA4xtxVTA-unsplash.jpeg'}
            className="w-full h-full"
            placeholder="blurred"
            quality={35}
            imgStyle={{
              borderRadius: '20px',
              overflow: 'hidden',
            }}
            imgClassName=""
          />
        }
        description={
          'Najčastejšou refrakčnou chybou oka, prejavuje sa neostrým videním predmetov vo vzdialenosti 5 a viac metrov.'
        }
        textClassName={{
          title: 'text-[19px]',
          wrapper: 'mx-3 sm:mx-6 mb-[37px] ',
        }}
        itemClassName={
          'sm:z-20  xl:z-0  lg:z-0 lg:w-[291px] lg:h-[353px]  md:ml-40 lg:ml-20 sm:top-1/2 right-10 md:right-0  md:left-1/2 transform md:-translate-x-1/2 sm:-translate-y-1/2'
        }
        imgClassName={'h-48 sm:h-[197px]'}
      />
    </div>
  );
};
