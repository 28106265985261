import { Button, Container, SectionHeader } from '@abclinic/ui';
import { Link } from 'gatsby';
import React from 'react';
import { routes } from '../../../lib/routes';
import { TechnologieItems } from './components/TechnologieItems';

export const TechnologiesSection = () => {
  return (
    <section className="relative">
      <Container>
        <div className="flex flex-col lg:flex-row gap-y-12 lg:gap-y-28 xl:gap-x-16 items-center">
          {/* TEXT CONTENT */}
          <div className="lg:w-2/5 xl:w-1/2">
            <SectionHeader
              title={'Vyšetríme Vás s tými  najmodernejšími technológiami'}
              subtitle={
                'Záleží nám na presnosti a kvalite našej práce a preto našu kliniku neustále inovujeme a do našeho arzenálu pridávame tie najnovšie a najkvalitnejšie stroje.'
              }
            />

            <Link
              to={routes.equipment.to}
              className="hidden mt-10 lg:mt-12 lg:block"
            >
              <Button variant="primary">Všetko naše vybavenie</Button>
            </Link>
          </div>

          {/* IMAGE  */}
          <div className="mt-6 lg:mt-0 lg:w-3/5 xl:w-1/22">
            <TechnologieItems />
          </div>
          <Link to={routes.equipment.to} className="lg:hidden">
            <Button variant="primary">Všetko naše vybavenie</Button>
          </Link>
        </div>
      </Container>
    </section>
  );
};
