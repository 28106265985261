import { StaticImage } from 'gatsby-plugin-image';
import { EquipmentProps } from '../components/Equipment';

import { graphql, useStaticQuery } from 'gatsby';
import { EquipmentItem } from '../../model/equipments.model';
//TODO: image on which side , and image :D

export const useEquipmentData = (): EquipmentItem[] => {
  const data = useStaticQuery(graphql`
    query getEquipmentItems {
      allStrapiEquipmentItem {
        nodes {
          shortDescription
          name
          image {
            alternativeText
            localFile {
              childImageSharp {
                gatsbyImageData(
                  quality: 100
                  layout: FULL_WIDTH
                  placeholder: BLURRED
                )
              }
            }
          }
        }
      }
    }
  `);
  return (
    data?.allStrapiEquipmentItem?.nodes.map((item: EquipmentItem) => ({
      name: item.name,
      shortDescription: item.shortDescription,
      image: item.image?.localFile.childImageSharp.gatsbyImageData,
    })) ?? []
  );
};
