import { HiOutlineCalendar } from '@react-icons/all-files/hi/HiOutlineCalendar';
import { StaticImage } from 'gatsby-plugin-image';
import { useTranslation } from 'react-i18next';
import { routes } from '../../../lib/routes';
import { HomeHero } from './HomeHero';

export interface HomeHeroSectionProps {
  className?: string;
}

export const HomeHeroSection = (props: HomeHeroSectionProps) => {
  const { className = '' } = props;
  const { t, i18n } = useTranslation(['common']);

  return (
    <HomeHero
      rightBgColor="primary"
      title={
        <>
          Svet už <span className="text-primary">nevyzerá</span> tak ako dakedy?
        </>
      }
      subtitle={
        'Prídte sa k nám vyšetriť a dovolte nám vrátiť vám váš zrak. Nech vám už neunikne ani jeden okamih.'
      }
      button={{
        href: routes.contact.to,
        label: 'Objednať sa',
        icon: <HiOutlineCalendar />,
      }}
      image={
        <StaticImage
          className="md:!absolute -bottom-1"
          placeholder="blurred"
          quality={95}
          src={'./../assets/hero-home.png'}
          alt={'KnowledgeShare hero image'}
          imgClassName=""
        />
      }
    />
  );
};
