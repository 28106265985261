import { BlogContainer } from '@abclinic/ui';
import loadable from '@loadable/component';
import { GatsbyImage, IGatsbyImageData } from 'gatsby-plugin-image';
import React, { useState } from 'react';

const ShareBlogActions = loadable(
  () => import('./ShareBlogActions').then((mod) => mod.ShareBlogActions),
  {
    ssr: false,
  }
);

export interface BlogHeaderSectionProps {
  image: IGatsbyImageData;
  title: string;
  alternativeTitle?: string;
  author: string;
  excerpt: string;
  userCanShareItem: boolean;
}

export const BlogHeaderSection = (props: BlogHeaderSectionProps) => {
  const { image, title, author, excerpt, alternativeTitle, userCanShareItem } =
    props;
  const [userCanShare, setUserCanShare] = useState(true);
  setUserCanShare(userCanShareItem);
  return (
    <header className="mb-10 md:mb-14 mt-6 md:mt-12 ">
      <BlogContainer>
        {/* Title */}
        <div className="top mb-8 space-y-4 md:text-xl font-light text-textTerciary">
          <div className="title flex flex-col space-y-4 ">
            <h1 className=" font-semibold  text-4xl lg:text-5xl text-titleText">
              <span>{title}</span>
            </h1>
            {userCanShare && <ShareBlogActions />}
          </div>
          <div className="excerpt font-light text-lg">{excerpt}</div>
        </div>
        {/* Image */}
        <div className="w-full h-56 sm:h-64 md:h-[35rem] rounded-3xl overflow-hidden">
          <GatsbyImage
            alt={title}
            imgClassName="w-full h-full rounded-3xl overflow-hidden"
            className="w-full h-full overflow-hidden rounded-3xl"
            image={image}
          />
        </div>
      </BlogContainer>
    </header>
  );
};
