import { EditorJsBody, PageSectionSpacer, StrapiSeo } from '@abclinic/ui';
import { graphql } from 'gatsby';
import { getImage } from 'gatsby-plugin-image';
import { useMemo } from 'react';
import { BlogHeaderSection } from '../../components/Blog/BlogHeaderSection';
import { Layout } from '../../components/Layout/Layout';
import { ContactFormWithContactInfoSection } from '../../components/Shared/components/ContactFormWithContactInfoSection/ContactFormWithContactInfoSection';

export interface ServicePageProps {
  data: any;
}

export const query = graphql`
  query Servicequery($slug: String!, $language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    strapiService(slug: { eq: $slug }) {
      seo {
        ...SeoFragment
      }

      name
      image {
        localFile {
          childImageSharp {
            gatsbyImageData
          }
        }
      }
      shortDescription
      content {
        data {
          content
        }
      }
    }
  }
`;

export const ServicePage = (props: ServicePageProps) => {
  const { data } = props;

  const body = useMemo(() => {
    const da = data?.strapiService?.content?.data?.content;
    return da ? JSON.parse(da)?.blocks : [];
  }, [data]);

  return (
    <Layout fullHeader>
      {data?.strapiService.seo && <StrapiSeo seo={data?.strapiService.seo} />}
      <PageSectionSpacer>
        {data && (
          <BlogHeaderSection
            image={getImage(
              data.strapiService.image.localFile.childImageSharp.gatsbyImageData
            )}
            title={data.strapiService.name}
            excerpt={data.strapiService.shortDescription}
            userCanShareItem={false}
          />
        )}
        <EditorJsBody blocks={body} />
        <ContactFormWithContactInfoSection />
      </PageSectionSpacer>
    </Layout>
  );
};

export default ServicePage;
