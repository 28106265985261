import { routes } from '../../../../lib/routes';
import { QuickNavigationCardProps } from './QuickNavigationCard';

export const quickNavigationTempData = [
  {
    title: 'Navigovať',
    subtitle: 'Zobrazte si našu kliniku na mape',
    linkTo: routes.contact.to,
    type: 'map',
  },
  {
    title: 'Objednať sa',
    subtitle: 'Vyberte si termín a objednajte sa u nás na vyšetrenie',
    linkTo: routes.contact.to,
    type: 'order',
  },
  // {
  //   title: 'Samovyšetrenie',
  //   subtitle: 'Vyplnte jednoduchý formulár a zistite čo Vám je.',
  //   linkTo: routes.knowledgeShare.to,
  //   type: "form"
  // },
  {
    title: 'Vzdelanie',
    subtitle: 'Dôlelžité informácie of chorobách a vadách očí',
    linkTo: routes.knowledgeShare.to,
    type: 'knowledgeshare',
  },
] as QuickNavigationCardProps[];
