import { Button, Container } from '@abclinic/ui';
import { Link } from 'gatsby';
import React from 'react';
import { Layout } from '../components/Layout/Layout';
import { routes } from '../lib/routes';

const NotFoundPage = () => (
  <Layout>
    <section className="py-32 md:py-64">
      <Container>
        <h1 className="justify-center flex flex-col items-center font-bold   text-center">
          <span className="flex text-primary text-[5rem] md:text-[10rem]">
            404
          </span>
          <span className="flex text-text font-semibold text-[1.5rem] md:text-[3rem]">
            Stránka nebola nájdená
          </span>
          <span className="mt-4 mb-8 font-light text-sm md:text-base">
            Nepodarilo sa nájsť stránku, ktorú hľadáte. Prosím skontrolujte,
            zadanú webovú adresu alebo prejdite na domov.
          </span>
          <Link to={routes.home.to}>
            <Button variant="primary">Späť na domov</Button>
          </Link>
        </h1>
      </Container>
    </section>
  </Layout>
);

export default NotFoundPage;
