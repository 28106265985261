import { graphql, useStaticQuery } from 'gatsby';
import { NoticeProps } from '../components/Notices/Notice';

export const useNotices = (): NoticeProps[] => {
  const data = useStaticQuery(graphql`
    query getNotices {
      allStrapiNotice {
        nodes {
          excerpt
          slug
          title
          id
        }
      }
    }
  `);
  return (
    data?.allStrapiNotice?.nodes.map((notice: NoticeProps) => ({
      title: notice.title,
      excerpt: notice.excerpt,
      slug: notice.slug,
    })) ?? []
  );
};
