import React from 'react';
import {
  QuickNavigationCard,
  QuickNavigationCardProps,
} from './QuickNavigationCard';

export interface QuickNavigationCardsProps {
  className?: string;
  navigation: QuickNavigationCardProps[];
}

export const QuickNavigationCards = (props: QuickNavigationCardsProps) => {
  const { className = '', navigation } = props;
  return (
    <div
      {...props}
      className={`grid sm:grid-cols-3  w-full gap-8 sm:gap-4 md:gap-8 max-w-5xl`}
    >
      {navigation.map((link) => (
        <QuickNavigationCard
          title={link.title}
          subtitle={link.subtitle}
          linkTo={link.linkTo}
          type={link.type}
          className="w-full h-40 sm:max-w-40   sm:h-52 md:max-w-72 lg:h-72 flex  "
        />
      ))}
    </div>
  );
};
