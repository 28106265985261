import React from 'react';
import { Control, useController } from 'react-hook-form';

export interface InputProps
  extends React.InputHTMLAttributes<HTMLInputElement> {
  className?: string;
  name: string;
  label: string;
  placeholder?: string;
  control: Control;
  rules?: any;
  type: string;
}

export const Input = (props: InputProps) => {
  const {
    className = '',
    control,
    name,
    type,
    rules,
    label,
    placeholder,
    ...restOfProps
  } = props;
  const {
    field: { onChange, onBlur, value, ref },
    fieldState: { invalid, isTouched, isDirty, error },
    formState: { touchedFields, dirtyFields },
  } = useController({
    name,
    control,
    defaultValue: '',
    rules: rules,
  });

  return (
    <div {...props} className={`border-none px-0 py-0 ${className}`}>
      <label
        htmlFor={name}
        className="text-dark text-sm leading-4 font-light border-none"
      >
        {label}
      </label>
      <input
        onChange={onChange} // send value to hook form
        onBlur={onBlur} // notify when input is touched/blur
        name={name}
        value={value}
        ref={ref}
        type={type}
        placeholder={placeholder}
        id={name}
        className="mt-2.5   focus:ring-2 border-none flex w-full  sm:text-sm  rounded-lg  bg-gray-100 text-text placeholder:text-black placeholder:text-opacity-20 lg:text-md py-4  px-6 font-light"
        {...restOfProps}
      />
      {error && (
        <div className="text-xs text-red-300 mt-2">{error['message']}</div>
      )}
    </div>
  );
};
