import React from 'react';
import { Button } from '../../button/Button';
import { Card } from '../../card/Card';
import { KnowledgeShareItem as KnowledgeShareItemProps } from './../../model/knowledgeShareItem.model';

export interface KnowledgeShareItemP extends KnowledgeShareItemProps {
  className?: string;
}

export const KnowledgeShareItem = (props: KnowledgeShareItemP) => {
  const { className = '', image, title, subtitle, perex } = props;
  return (
    <Card
      {...props}
      className={`flex flex-col justify-between h-full ${className}`}
    >
      <div className="relative">{image}</div>
      <div className="body p-6 flex flex-auto justify-between flex-col">
        <div className="content">
          <header>
            <h3 className="text-terciary font-semibold text-2xl leading-7 mt-2">
              {title}
            </h3>
            <p className="text-primary font-semibold text-xl">{subtitle}</p>
          </header>

          <p className=" pt-3 md:pt-5 text-text font-light leading-6 text-sm md:text-base mb-4 md:mb-10 inline-block">
            {perex}
          </p>
        </div>
        <div className="actions">
          <Button variant="transparent" icon className={'flex !justify-start'}>
            Čítať viac
          </Button>
        </div>
      </div>
    </Card>
  );
};
