import { Button } from '@abclinic/ui';
import { HiOutlineCalendar } from '@react-icons/all-files/hi/HiOutlineCalendar';
import { Link } from 'gatsby';
import React from 'react';
import { LogoLink } from '../../../Layout/components/LogoLink/LogoLink';
import { routes as webroutes } from '../../../../lib/routes';

export interface MobileNavigationProps {
  className?: string;
  onLinkClicked: () => void;
  routes: {
    to: string;
    label: string;
  }[];
}

export const MobileNavigation = (props: MobileNavigationProps) => {
  const { routes = [], className, onLinkClicked } = props;

  return (
    <div
      className={`bg-background1 z-50 top-0 w-screen h-screen fixed py-4 flex justify-center items-center duration-[400ms] min-h-[200px] overflow-y-scroll delay-100	ease-in-out	transition-all ${className}`}
    >
      {/* LOGO  */}
      <div className="logo absolute top-[26px] right-0 left-0 mx-auto w-full flex justify-center">
        <LogoLink className="w-40" />
      </div>

      <div className=" max-h-full w-full">
        {/* LINKS  */}
        <div className="flex justify-between items-center flex-col  gap-y-10 pb-10 pt-24  w-full ">
          {routes.map((route) => (
            <Link
              to={route.to}
              onClick={onLinkClicked}
              className="text-textTerciary font-medium  text-xl leading-4"
              activeClassName={'text-gray-800'}
            >
              <span className="">{route.label}</span>
            </Link>
          ))}

          <a href={webroutes.contact.to}>
            <Button
              variant="primary"
              className="!rounded-2xl !text-base"
              icon={<HiOutlineCalendar />}
            >
              Objednať sa
            </Button>
          </a>
        </div>
      </div>
    </div>
  );
};
