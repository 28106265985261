import React from 'react';
import { ILinkBlock } from "../model/editorjs";

export type LinkBlockProps = ILinkBlock

export const LinkBlock = (props: LinkBlockProps) => {
  const { data: { link, meta } } = props
  return (
    <a href={link} className="group">
      <div className="flex border rounded-3xl overflow-hidden !my-12 group-hover:shadow-lg flex-col md:flex-row">
        <img src={meta.image.url} alt="" className=' flex xl:w-52 md:w-40 w-full object-cover' />
        <div className="info p-6 lg:p-10 space-y-1">
          <div className="title text-lg font-semibold text-terciary">{meta.title}</div>
          <div className="description text-sm text-textTerciary">{meta.description}</div>
        </div>
      </div>
    </a>
  );
};
