import { ImSpinner2 } from '@react-icons/all-files/im/ImSpinner2';
import clsx from 'clsx';
import React, { ReactNode } from 'react';

export interface ButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  className?: string;
  children: ReactNode;
  variant?: 'primary' | 'secondary' | 'terciary' | 'transparent';
  loading?: boolean;
  type?: 'button' | 'submit' | 'reset' | undefined | string;
  icon?: React.ReactNode;
}

export const Button = (props: ButtonProps) => {
  const {
    className = '',
    children,
    loading,
    icon,
    type,
    variant,
    ...restOfProps
  } = props;
  return (
    <button
      type={type}
      className={clsx({
        ' py-3 px-8 shadow-xl rounded-lg duration-300 flex items-center justify-center space-x-3 font-extralight text-base':
          true,
        'bg-primary text-white hover:shadow-2xl': variant === 'primary',
        'bg-transparent text-primary shadow-none px-0 hover:opacity-60 font-medium !p-0':
          variant === 'transparent',
        'bg-background text-ultraUltra': variant === 'secondary',
        'bg-terciary text-white ': variant === 'terciary',
        [className]: true,
      })}
      {...restOfProps}
    >
      {children}
      {icon && (
        <span className="ml-3">
          {loading ? <ImSpinner2 className="animate-spin" /> : icon}
        </span>
      )}
      {loading && !icon && <ImSpinner2 className="ml-3 animate-spin" />}
    </button>
  );
};
