import React from 'react';
import { BlogContainer } from '../container/BlogContainer';
import { HeaderBlock } from './blocks/HeaderBlock';
import { ImageBlock } from './blocks/ImageBlock';
import { LinkBlock } from './blocks/LinkBlock';
import { ListBlock } from './blocks/ListBlock';
import { ParagraphBlock } from './blocks/ParagraphBlock';
import { QuoteBlock } from './blocks/QuoteBlock';
import { RawBlock } from './blocks/RawBlock';
import { TableBlock } from './blocks/TableBlock';
import { BlockType, EditorBlock } from './model/editorjs';
import './content.css';

export interface EditorJsBodyProps {
  blocks: EditorBlock[];
}

const BlockTypeRendererMap: Record<Partial<BlockType>, React.FC<any>> = {
  header: HeaderBlock,
  image: ImageBlock,
  paragraph: ParagraphBlock,
  list: ListBlock,
  table: TableBlock,
  quote: QuoteBlock,
  embed: () => <div>embed</div>,
  raw: RawBlock,
  LinkTool: LinkBlock,
};

export const EditorJsBody = (props: EditorJsBodyProps) => {
  const { blocks } = props;
  return (
    <BlogContainer>
      <div className="space-y-4 editor__body">
        {blocks.map((block, index) => {
          const Element = BlockTypeRendererMap[block.type];
          return (
            <>
              {BlockTypeRendererMap[block.type] && (
                <Element key={index} {...block} />
              )}
            </>
          );
        })}
      </div>
    </BlogContainer>
  );
};
