import { Link } from 'gatsby';
import { Container } from '../../container/Container';
import {
  SectionHeader,
  SectionHeaderProps,
} from '../../section/sectionHeader/SectionHeader';
import { BlogCard, BlogCardProps } from './components/BlogCard';
import * as React from 'react';

export interface BlogSectionProps {
  className?: string;
  articles: BlogCardProps[];
  header?: SectionHeaderProps;
}

export const BlogSection = (props: BlogSectionProps) => {
  const { className = '', articles, header } = props;

  return (
    <Container className="flex flex-col space-y-12 md:space-y-16">
      {header && <SectionHeader {...header} />}
      <div className="gap-8 md:gap-16 grid xl:grid-cols-3 md:grid-cols-2 grid-cols-1  w-full min-w-[16rem]">
        {articles?.map((article) => (
          <Link to={article.route}>
            <BlogCard key={article.slug} {...article} />
          </Link>
        ))}
      </div>
    </Container>
  );
};
