import { Link } from 'gatsby';

export interface FooterLinkSectionProps {
  className?: string;
  title: string;
  links: {
    href: string;
    title: string;
  }[];
}

const FooterLink = (props: { to: string; title: string }) => {
  return (
    <Link
      to={props.to}
      className="text-textTerciary font-light hover:opacity-70"
    >
      {props.title}
    </Link>
  );
};

export const FooterLinkSection = (props: FooterLinkSectionProps) => {
  const { className = '', title, links } = props;
  return (
    <article {...props} className="">
      <h3 className=" mb-2 text-text opacity-75 font-semibold">{title}</h3>

      <div className="links flex flex-col gap-3 mt-3">
        {links.map((link) => (
          <FooterLink key={link.href} to={link.href} title={link.title} />
        ))}
      </div>
    </article>
  );
};
