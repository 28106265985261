exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-500-tsx": () => import("./../../../src/pages/500.tsx" /* webpackChunkName: "component---src-pages-500-tsx" */),
  "component---src-pages-clanky-index-tsx": () => import("./../../../src/pages/clanky/index.tsx" /* webpackChunkName: "component---src-pages-clanky-index-tsx" */),
  "component---src-pages-clanky-strapi-article-slug-tsx": () => import("./../../../src/pages/clanky/{StrapiArticle.slug}.tsx" /* webpackChunkName: "component---src-pages-clanky-strapi-article-slug-tsx" */),
  "component---src-pages-cookies-tsx": () => import("./../../../src/pages/cookies.tsx" /* webpackChunkName: "component---src-pages-cookies-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-kontakt-tsx": () => import("./../../../src/pages/kontakt.tsx" /* webpackChunkName: "component---src-pages-kontakt-tsx" */),
  "component---src-pages-o-nas-tsx": () => import("./../../../src/pages/o-nas.tsx" /* webpackChunkName: "component---src-pages-o-nas-tsx" */),
  "component---src-pages-ochrana-osobnych-udajov-tsx": () => import("./../../../src/pages/ochrana-osobnych-udajov.tsx" /* webpackChunkName: "component---src-pages-ochrana-osobnych-udajov-tsx" */),
  "component---src-pages-oci-index-tsx": () => import("./../../../src/pages/oci/index.tsx" /* webpackChunkName: "component---src-pages-oci-index-tsx" */),
  "component---src-pages-oci-strapi-knowledge-share-article-slug-tsx": () => import("./../../../src/pages/oci/{StrapiKnowledgeShareArticle.slug}.tsx" /* webpackChunkName: "component---src-pages-oci-strapi-knowledge-share-article-slug-tsx" */),
  "component---src-pages-sluzby-index-tsx": () => import("./../../../src/pages/sluzby/index.tsx" /* webpackChunkName: "component---src-pages-sluzby-index-tsx" */),
  "component---src-pages-sluzby-strapi-service-slug-tsx": () => import("./../../../src/pages/sluzby/{StrapiService.slug}.tsx" /* webpackChunkName: "component---src-pages-sluzby-strapi-service-slug-tsx" */),
  "component---src-pages-vybavenie-index-tsx": () => import("./../../../src/pages/vybavenie/index.tsx" /* webpackChunkName: "component---src-pages-vybavenie-index-tsx" */),
  "component---src-pages-zdielanie-znalosti-tsx": () => import("./../../../src/pages/zdielanie-znalosti.tsx" /* webpackChunkName: "component---src-pages-zdielanie-znalosti-tsx" */)
}

