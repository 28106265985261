import React from 'react';
import { ContactPageContextProvider } from '../components/Contact/ContactPageContext/ContactPageContextProvider';
import { KnowledgeShareFeaturedArticleSection } from '../components/KnowledgeShare/FeaturedArticleSection/KnowledgeShareFeaturedArticleSection';
import { KnowledgeShareHeroSection } from '../components/KnowledgeShare/Hero/KnowledgeShareHeroSection';
import { KnowledgeShareContextProvider } from '../components/KnowledgeShare/KnowledgeSharePageContext/KnowledgeShareContextProvider';
import { KnowledgeShareCtaBanner } from '../components/KnowledgeShare/KnowledgeShareCtaBanner/KnowledgeShareCtaBanner';
import { KnowledgeSharePageSection } from '../components/KnowledgeShare/KnowledgeShareSection/KnowledgeSharePageSection';
import { Layout } from '../components/Layout/Layout';

export const KnowledgeSharePage = ({ data }: any) => {
  const datac = [];
  return (
    <Layout headerBtnType="primary">
      {/* <KnowledgeShareContextProvider data={{ ...data }}> */}
      {/* TODO: add page context  */}
      <div className="space-y-20 lg:space-y-40 mt-96">
        {/* <KnowledgeShareHeroSection /> */}
        {/* <KnowledgeShareFeaturedArticleSection /> */}
        {/* <KnowledgeSharePageSection /> */}
        <KnowledgeShareCtaBanner />
        {/* <KnowledgeShareContactSection /> */}
      </div>
      {/* </KnowledgeShareContextProvider> */}
    </Layout>
  );
};

export default KnowledgeSharePage;
