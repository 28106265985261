import React from 'react';

export interface DisorderArticleItemProps {
  itemClassName: string;
  title: string;
  description: string;
  textClassName: {
    wrapper: string;
    title: string;
    description?: string;
  };
  imgClassName: string;
  image: React.ReactNode;
}

export const DisorderArticleItem = (props: DisorderArticleItemProps) => {
  const {
    title,
    description,
    itemClassName,
    textClassName,
    imgClassName,
    image,
  } = props;
  return (
    <article
      className={`sm:absolute w-full  sm:w-2/5 flex-inline bg-white rounded-2xl shadow-2xl border-[1px] border-solid  border-black border-opacity-5  ${itemClassName}`}
    >
      {/* IMAGE  */}
      <div className="overflow-hidden rounded-2xl">
        <div
          className={`relative  w-full flex items-center justify-center   overflow-hidden rounded-2xl ${imgClassName}`}
        >
          {image}
        </div>
      </div>

      {/* TEXT  */}
      <dl className={`mt-5 ${textClassName.wrapper}`}>
        <dt className={`text-black leading-5 font-bold ${textClassName.title}`}>
          {title}
        </dt>
        <dd
          className={` text-text opacity-80 text-xs leading-4  mt-2 ${textClassName.description}`}
        >
          {description}
        </dd>
      </dl>
    </article>
  );
};
