import React from 'react';

export interface ContactInfosProps {
  className?: string;
  items: {
    title: string;
    icon: React.ReactNode;
    label: string;
    href: string;
  }[];
}

export const ContactInfos = (props: ContactInfosProps) => {
  const { items, className } = props;

  return (
    <article className={`flex flex-col mt-12 ${className}`}>
      <div className="content space-y-9 md:space-y-12">
        {items.map((item) => (
          <a
            target="_blank"
            rel="noopener noreferrer"
            href={item.href}
            className="flex space-x-4 items-center"
          >
            <div className="icon text-base md:text-3xl text-white">
              {item.icon}
            </div>
            <div>
              <div className=" text-sm label text-slate-400 font-light opacity-90 hover:opacity-75">
                {item.title}
              </div>
              <div className=" text-base label text-background1 font-light opacity-90 hover:text-white hover:opacity-75">
                {item.label}
              </div>
            </div>
          </a>
        ))}
      </div>
    </article>
  );
};
