import { Link } from 'gatsby-plugin-react-i18next';
import React from 'react';
import { Article } from '../../model/article.model';
import { FeaturedArticle } from './FeaturedArticle';

export interface FeaturedArticleSectionProps {
  className?: string;
  featuredArticles: Article[];
}

export const FeaturedArticleSection = (props: FeaturedArticleSectionProps) => {
  const { className = '', featuredArticles } = props;
  return (
    <section {...props} className="py-3">
      <div className="grid grid-cols-1 md:grid-cols-2 gap-12 py-12 lg:py-20">
        {featuredArticles.map((article) => (
          <Link to={article.route}>
            <FeaturedArticle {...article} className="xl:ml-16" />
          </Link>
        ))}
      </div>
    </section>
  );
};
