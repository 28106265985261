import { HeroProps } from '@abclinic/ui';
import { StaticImage } from 'gatsby-plugin-image';
import { SharedHero } from '../../Shared/components/SharedHero/SharedHero';

export interface EquipmentsHeroSectionProps extends HeroProps {
  className?: string;
}
export const EquipmentsHeroSection = (props: EquipmentsHeroSectionProps) => {
  return (
    <section className="relative overflow-hidden  md:bg-background1">
      <SharedHero
        titleBlue={props.titleBlue}
        title={props.title}
        subtitle={props.subtitle}
        button={{
          label: props.button?.label,
          href: props.button?.href,
        }}
        image={
          <div className="relative w-full md:absolute mt-auto -top-16 -mb-10 left-auto bottom-0 md:translate-x-0 -right-20  flex md:w-[32rem]   lg:w-[40rem] h-full items-center">
            <StaticImage
              placeholder="tracedSVG"
              quality={95}
              src={'./../assets/hero-equipments.png'}
              alt={`${props.title} ${props.titleBlue}`}
              title={`${props.title} ${props.titleBlue}`}
              imgClassName="w-full h-full"
            />
          </div>
        }
      />
    </section>
  );
};
