import * as React from 'react';

const SvgKnowledgeshare = (props) => (
  <svg
    width={54}
    height={68}
    viewBox="0 0 54 68"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M1.9165 8.91667C1.9165 4.95863 5.12513 1.75 9.08317 1.75H44.9165C48.8745 1.75 52.0832 4.95863 52.0832 8.91667V66.25L26.9998 53.7083L1.9165 66.25V8.91667Z"
      stroke="#185FD4"
      strokeWidth={3}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default SvgKnowledgeshare;
