import React from 'react';

export interface ContactInfoCardProps {
  className?: string;
  title: string;
  items: {
    icon: React.ReactNode;
    label: string;
    sublabel: string;
    href: string;
  }[];
}

export const ContactInfoCard = (props: ContactInfoCardProps) => {
  const { items, title, className } = props;

  return (
    <article
      className={`px-4 md:px-8 pt-6 pb-7 bg-white rounded-2xl flex flex-col  ${className}`}
    >
      <header className="mb-4">
        <h2 className="text-ultraUltra font-bold text-base md:text-lg">
          {title}
        </h2>
      </header>
      <div className="content space-y-4 md:min-w-[14rem]">
        {items.map((item) => (
          <div key={item.label}>
            <a target="_blank" rel="noopener noreferrer" href={item.href}>
              <div className="flex space-x-2 md:space-x-4 items-center max-h-[2.8rem]  overflow-hidden">
                <div className="icon text-2xl md:text-2xl text-dark">
                  {item.icon}
                </div>
                <div className="max-w-[11rem] text-sm md:text-base label text-secondary font-light opacity-90 hover:text-ultraUltra hover:opacity-75">
                  {item.label}
                  {item.sublabel && (
                    <span className="block text-[0.6rem] md:text-xs label text-secondary text-opacity-90 font-light">
                      {item.sublabel}
                    </span>
                  )}
                </div>
              </div>
            </a>
          </div>
        ))}
      </div>
    </article>
  );
};
