import { GatsbySeo } from 'gatsby-plugin-next-seo';
import { Helmet } from 'react-helmet';
import { StrapiSeoNamespace } from '../model/strapi-seo';
import React from 'react';

export interface StrapiSeoProps {
  seo: StrapiSeoNamespace.Root;
}

// const cspDirectives = [
//   "script-src: 'self' www.google-analytics.com https://backoffice.abclinic.momentumdigital.sk https://maps.googleapis.com",
//   "style-src: 'self' 'unsafe-inline' https://maps.googleapis.com",
//   "img-src: 'self' data: www.google-analytics.com https://maps.googleapis.com",
// ];

export const StrapiSeo = (props: StrapiSeoProps) => {
  const { seo } = props;

  return (
    <>
      <GatsbySeo
        title={`${seo.metaTitle} | Abelovska Očná ambulancia`}
        description={seo.metaDescription}
        canonical={seo?.canonicalURL}
        openGraph={{
          title: seo?.metaSocial[0]?.title,
          description: seo?.metaSocial[0]?.description,
          images: [
            {
              url: seo?.metaSocial[0]?.image?.localFile?.url,
              width: 800,
              height: 600,
              alt: seo?.metaSocial[0]?.image?.alternativeText,
            },
          ],
        }}
        languageAlternates={[
          {
            hrefLang: 'sk',
            href: 'https://abelovska.sk',
          },
          // {
          //   hrefLang: 'en',
          //   href: 'https://abelovska.sk/en',
          // },
        ]}
      />
      <Helmet>

      <meta name="apple-mobile-web-app-status-bar-style" content="default" />
      <meta name="apple-mobile-web-app-capable" content="yes" />
      <meta name="apple-mobile-web-app-title" content="Abelovska, Očna ambulancia" />
      {/* <meta
        http-equiv="Content-Security-Policy"
        content={`default-src 'self' 'unsafe-inline' 'unsafe-eval' https://analytics.momentumdigital.sk  https://backoffice.abclinic.momentumdigital.sk https://maps.googleapis.com`}
        /> */}
        </Helmet>
    </>
  );
};
