import { Container } from '@abclinic/ui';
import { HiOutlineCalendar } from '@react-icons/all-files/hi/HiOutlineCalendar';
import { routes } from '../../../lib/routes';
import { StaticImage } from 'gatsby-plugin-image';
import { CtaBanner } from '../../Shared/components/CtaBanner/CtaBanner';

export interface KnowledgeShareCtaBannerProps {
  className?: string;
  title: string;
  titleBlue?: string;
  description?: string;
  button?: {
    label: string;
    href: string;
  };
}

export const KnowledgeShareCtaBanner = (
  props: KnowledgeShareCtaBannerProps
) => {
  const { title, titleBlue, description, button, ...restOfProps } = props;
  return (
    <Container>
      <CtaBanner
        title={title}
        alternativeTitle={titleBlue}
        content={description}
        button={{
          label: button?.label,
          href: button?.href,
          icon: <HiOutlineCalendar />,
        }}
        image={
          <>
            <StaticImage
              className="hidden md:block"
              placeholder="tracedSVG"
              quality={35}
              src={'./assets/ctaimage.png'}
              alt={'KnowledgeShare cta image'}
              imgClassName="hidden md:block"
            />
            <StaticImage
              className="md:hidden"
              placeholder="tracedSVG"
              quality={35}
              src={'./assets/ctaimage-mobile.png'}
              alt={'KnowledgeShare cta image'}
              imgClassName="hidden md:block"
            />
          </>
        }
      />
    </Container>
  );
};
