import { BlogCardProps, BlogSection, Button } from '@abclinic/ui';
import React from 'react';
import { useInfiniteQuery } from 'react-query';
import { fetchArticles } from '../../lib/api/articles';
import { ArticlePage } from '../../lib/features/articles/model/article.model';
import { mapArticleCardFragmentToCardProps } from '../../lib/features/articles/utils';

export interface ArticleListControllerProps {
  articles: ArticlePage;
}

export const ArticleListController = (props: ArticleListControllerProps) => {
  const { articles: initialPage } = props;

  const {
    data,
    fetchNextPage,
    fetchPreviousPage,
    hasNextPage,
    hasPreviousPage,
    isFetchingNextPage,
    isFetchingPreviousPage,
    isFetching,
    isLoading,
    ...result
  } = useInfiniteQuery<ArticlePage, any>(
    'infiniteArticles',
    async ({ pageParam = 1 }) => {
      return fetchArticles(pageParam);
    },
    {
      initialData: {
        pages: [initialPage],
        pageParams: [1],
      },
      refetchOnWindowFocus: false,
      getNextPageParam: (lastPage, allPages) => {
        return lastPage?.pageInfo?.hasNextPage
          ? lastPage?.pageInfo?.currentPage + 1
          : null;
      },
    }
  );

  const allArticles = React.useMemo<BlogCardProps[]>(() => {
    return (
      data?.pages.reduce<BlogCardProps[]>((acc, page) => {
        return [...acc, ...page.nodes.map(mapArticleCardFragmentToCardProps)];
      }, []) || []
    );
  }, [data]);

  return (
    <div className="flexe justify-center items-center gap-x-10">
      <div className="flex">
        <BlogSection articles={allArticles} />
      </div>

      <div className="flex justify-center mt-16">
        {hasNextPage && (
          <Button
            variant="primary"
            loading={isFetching}
            onClick={() => {
              fetchNextPage();
            }}
          >
            {isFetchingNextPage ? 'Načítavam...' : 'Daľšie články'}
          </Button>
        )}
      </div>
    </div>
  );
};
