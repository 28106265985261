import { FaMapMarkerAlt } from '@react-icons/all-files/fa/FaMapMarkerAlt';
import { StaticImage } from 'gatsby-plugin-image';
import { GoogleApiWrapper, Map, Marker } from 'google-maps-react';
import React, { Component } from 'react';
import myicon from '../../assets/pin.png';
import mapStyles from './mapStyles';

const customLatitude = '48.58109251006909';
const customLongtitude = '19.126859510918166';
const customIcon = (
  <FaMapMarkerAlt className="absolute text-primary z-10 text-4xl sm:text-6xl bottom-[25%] left-[58%]" />
);
class MapContainer extends Component<{ className: string }, any> {
  state = {
    myMarkers: [{ latitude: customLatitude, longitude: customLongtitude }],
  };

  displayMarkers = () => {
    return this.state.myMarkers.map((mark, index) => {
      return (
        <Marker
          id={index}
          position={{ lat: mark.latitude, lng: mark.longitude }}
          onClick={() => {}}
          icon={{
            url: myicon,
            scaledSize: new google.maps.Size(31, 43),
          }}
          aria-label="Map marker"
        />
      );
    });
  };

  override render() {
    return (
      <div
        className={`map w-full relative overflow-hidden rounded-bl-3xl rounded-3xl lg:rounded-t-none lg:rounded-br-none lg:rounded-bl-3xl shadow-lg  ${this.props?.className}`}
      >
        <Map
          google={this.props.google}
          zoom={15}
          styles={mapStyles.styles}
          disableDefaultUI
          initialCenter={{ lat: customLatitude, lng: customLongtitude }}
          center={{ lat: customLatitude, lng: customLongtitude }}
        >
          {this.displayMarkers()}
        </Map>
      </div>
    );
  }
}

export default GoogleApiWrapper({
  apiKey: 'AIzaSyDYr0mwlAA_RfXbQwZRqo16xQ06paoov28',
})(MapContainer);
