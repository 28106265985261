import React from 'react';
import { Container, SectionHeader, SectionHeaderProps } from '@abclinic/ui';
import { StaticImage } from 'gatsby-plugin-image';
import { IStaticImageProps } from 'gatsby-plugin-image/dist/src/components/static-image.server';

export default interface IframeSectionProps {
  embedUrl: string;
  header?: SectionHeaderProps;
}

export const IframeSection = (props: IframeSectionProps) => {
  const { embedUrl, header } = props;

  return (
    <Container className="my-20 md:mb-20 bg-white md:mt-24">
      {header && <SectionHeader {...header} />}
      <div className="">
        <div className="w-full aspect-video mt-6 md:mt-12 rounded-2xl overflow-hidden shadow-2xl">
          <iframe
            src={embedUrl}
            height={'100%'}
            width={'100%'}
            allowFullScreen={true}
            className="rounded-2xl"
            loading="lazy"
            title={embedUrl}
            referrerPolicy="no-referrer-when-downgrade"
          />
        </div>
      </div>
    </Container>
  );
};
