import { Button } from '@abclinic/ui';
import { FaRegCheckCircle } from '@react-icons/all-files/fa/FaRegCheckCircle';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import React from 'react';

export interface SuccessModalProps {
  open?: boolean;
  onClose?: () => void;
}

export const SuccessModal = (props: SuccessModalProps) => {
  const { open, onClose } = props;
  const { t } = useTranslation();

  if (!open) {
    return null;
  }

  return (
    <div className="fixed top-0 bottom-0 left-0 right-0 flex justify-center items-center">
      <div className="overlay bg-ultraUltra opacity-40 absolute top-0 bottom-0 left-0 right-0 z-0"></div>

      <div className="modal w-auto p-10 bg-white rounded-3xl relative z-10 text-center flex flex-col justify-center mx-4 items-center">
        <div className="icon text-primary text-5xl flex justify-center">
          <FaRegCheckCircle />
        </div>
        <div className="text my-8 text-base lg:text-lg max-w-sm">
          {t('form.successMessage')}
        </div>
        <div className="actions flex justify-center">
          <Button variant='primary' onClick={onClose}>
            Ok
          </Button>
        </div>
      </div>
    </div>
  );
};
