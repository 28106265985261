import { Button, Container } from '@abclinic/ui';
import { FaChevronRight } from '@react-icons/all-files/fa/FaChevronRight';
import { HiOutlineCalendar } from '@react-icons/all-files/hi/HiOutlineCalendar';
import { HiOutlineClock } from '@react-icons/all-files/hi/HiOutlineClock';
import { HiOutlineLocationMarker } from '@react-icons/all-files/hi/HiOutlineLocationMarker';
import { HiOutlineMail } from '@react-icons/all-files/hi/HiOutlineMail';
import { HiOutlinePhone } from '@react-icons/all-files/hi/HiOutlinePhone';
import { Link, useTranslation } from 'gatsby-plugin-react-i18next';
import { routes } from '../../../../lib/routes';
import { ContactInfoCard } from '../../../Home/Hero/ContactInfoCard';
import { useContactInfoItems } from '../../hooks/useContactInfoItems';
import MapContainer from '../CustomGoogleMap/MapContainer';

export const BookAnAppointmentSection = () => {
  const contactInfo = useContactInfoItems();
  const { t } = useTranslation();

  return (
    <section className="relative flex flex-col lg:flex-row  items-start lg:items-center justify-between gap-x-10  xl:gap-x-28">
      {/* MAP */}
      <div className="bg-mapBackgournd text-transparent px-6 lg:px-0 order-2  lg:order-1 w-full  lg:w-1/2   ">
        <MapContainer
          className={
            'h-[20rem] md:h-[40rem] lg:!rounded-r-3xl lg:!rounded-l-none '
          }
        />
      </div>

      <div className=" w-full lg:w-1/2 order-1 lg:order-2 mb-20 lg:mb-0">
        <Container className="">
          <ContactInfoCard
            className="!shadow-none !bg-transparent !p-0"
            title={'Objednajte sa na vyšetrenie'}
            items={[
              {
                icon: <HiOutlineLocationMarker />,
                ...contactInfo.address,
              },
              {
                icon: <HiOutlineClock />,
                ...contactInfo.openingHours,
              },
              {
                icon: <HiOutlineMail />,
                ...contactInfo.email,
              },
              {
                icon: <HiOutlinePhone />,
                ...contactInfo.phone,
              },
            ]}
          />

          <div className="flex flex-col sm:flex-row gap-y-10 sm:gap-y-0 sm:gap-x-10 mt-16">
            <Link to={`${routes.contact.to}`}>
              <Button variant="primary" icon={<HiOutlineCalendar />}>
                {t('bookAppointment')}
              </Button>
            </Link>

            {/* <Link to={`${routes.knowledgeShare.to}`}>
              <Button icon={<FaChevronRight />}>{t('examineYourself')}</Button>
            </Link> */}
          </div>
        </Container>
      </div>
    </section>
  );
};
