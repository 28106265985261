import { api } from '../../api/api';

/**
 * Sends a request to the server to create a new form submission.
 * @param values
 * @returns
 */
export const submitForm = async (values: any) => {
  const { data } = await api({
    url: `/api/contact-form-submissions`,
    method: 'POST',
    data: {
      data: values,
    },
  });

  return data;
};
