import { Container, FeaturedArticleSection, SectionHeader } from '@abclinic/ui';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import React from 'react';
import { generateKnowledgeBaseArticleRoute } from '../../../lib/routes';
import { useKnowledgeSharePage } from '../KnowledgeSharePageContext/KnowledgeShareContextProvider';

export interface KnowledgeShareFeaturedArticleSectionProps {
  className?: string;
  sectionHeader?: {
    title: string;
    subtitle: string;
  };
}

export const KnowledgeShareFeaturedArticleSection = (
  props: KnowledgeShareFeaturedArticleSectionProps
) => {
  const { sectionHeader, className = '' } = props;
  const { regularArticles } = useKnowledgeSharePage();
  return (
    <section className="">
      <Container {...props}>
        <SectionHeader
          title={sectionHeader?.title}
          subtitle={sectionHeader?.subtitle}
        />
        <FeaturedArticleSection
          featuredArticles={regularArticles.knowledge_share_articles.map(
            (article) => {
              return {
                title: article.title,
                slug: article.slug,
                subtitle: article.alternativeTitle,
                link: generateKnowledgeBaseArticleRoute(article.slug),
                perex: article.excerpt,
                route: generateKnowledgeBaseArticleRoute(article.slug),
                image: (
                  <GatsbyImage
                    alt={article.image.alternativeText}
                    className="h-52  xl:w-52  w-full xl:-left-20  left-0 top-0 relative rounded-2xl"
                    imgClassName="rounded-2xl overflow-hidden shadow-lg"
                    image={getImage(
                      article.image.localFile.childImageSharp.gatsbyImageData
                    )}
                  />
                ),
              };
            }
          )}
        />
      </Container>
    </section>
  );
};
