import { Button, Container, SectionHeader } from '@abclinic/ui';
import { Link } from 'gatsby';
import React from 'react';
import { DisorderArticleItems } from './components/DisorderArticleItems';

export interface EyesDisordesArticlesSectionProps {
  className?: string;
}

const ViewMoreButton = () => {
  return (
    <Link to={'/oci'}>
      <Button variant="primary" icon>
        Všetky poruchy
      </Button>
    </Link>
  );
};

export const EyesDisordesArticlesSection = (
  props: EyesDisordesArticlesSectionProps
) => {
  const { className = '' } = props;
  return (
    <section className="">
      <Container>
        <div className=" h-full flex flex-col gap-y-12 md:gap-y-20 lg:gap-x-4 lg:gap-y-0 lg:flex-row min-h-[40rem] items-center justify-center">
          {/* ARTICLES  */}
          <DisorderArticleItems className="relative w-full  lg:w-1/2  h-full min-h-[649px] order-2" />
          {/* INTOR TEXT  */}
          <div className=" w-full lg:w-1/2 flex-inline items-center order-1 lg:order-last">
            <SectionHeader
              darkTitle
              title={'Poruchy oka a očné choroby'}
              subtitle={`Ochorenia a vady zraku sú rôzne. Prečítajte si o tých najznámejších a najrozšírenejších. Aké sú ich symptómy, aké sú ich príčiny a ako Vám s daným ochorením dokážeme profesionálne pomôcť.`}
            />
            <div className="hidden md:flex order-2 mt-12">
              <ViewMoreButton />
            </div>
          </div>
          <div className="flex md:hidden order-2">
            <ViewMoreButton />
          </div>
        </div>
      </Container>
    </section>
  );
};
