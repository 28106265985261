import { BlogCardProps } from '@abclinic/ui';
import axios from 'axios';
import { useInfiniteQuery } from 'react-query';
import { ArticlePage } from '../features/articles/model/article.model';
import { api } from './api';

const mapApiArticlesToBlogProps = (article: any): BlogCardProps => ({
  title: article.attributes.title,
  image: (
    <img
      src={article.attributes.image.data.attributes.url}
      alt={article.attributes.title}
    />
  ),
  route: '',
  perex: article.attributes.excerpt,
  slug: article.attributes.slug,
});

export const fetchArticles = async (page: number): Promise<ArticlePage> => {
  const { data } = await axios({
    method: 'get',
    url: `${__PATH_PREFIX__}/json-data/articles-${page}.json`,
  });

  return data;
};
