import { routes } from '../../../../lib/routes';
import { HeaderNavigation } from './HeaderNavigation';

export interface HeaderProps {
  full?: boolean;
  headerBtnType?: string;
}

export const Header = (props: HeaderProps) => {
  const { full = false, headerBtnType } = props;
  return (
    <>
      <header className="h-20 lg:h-28 flex items-center absolute top-0 left-0 right-0 z-50">
        <HeaderNavigation
          routes={Object.values(routes).filter(
            (route) => route.hideInHeader !== true
          )}
          headerBtnType={headerBtnType}
        />
      </header>

      {full && <div className="h-28" />}
    </>
  );
};
