import { Link } from 'gatsby';
import React from 'react';
import { ReactComponent as Logo } from '../../assets/logo.svg';

export interface LogoLinkProps {
  className?: string;
}

export const LogoLink = (props: LogoLinkProps) => {
  const { className = '', ...restOfProps } = props;
  return (
    <Link
      to="/"
      className={`block ${className}`}
      aria-label="ABclinic logo"
      {...restOfProps}
    >
      <Logo />
    </Link>
  );
};
