import { Button, Container, HeroProps, HeroTitle } from '@abclinic/ui';
import { Link } from 'gatsby';

export interface SharedHeroProps extends HeroProps {
  className?: string;
}

export const SharedHero = (props: SharedHeroProps) => {
  const {
    className = '',
    title,
    subtitle,
    titleBlue,
    button,
    image,
    bgColor,
  } = props;
  return (
    <section
      className={`pt-36 lg:pt-0  ${
        image
          ? 'md:h-screen max-h-[100rem]  min-h-[36rem]'
          : 'h-[22rem] md:h-[36rem]'
      }  flex items-center relative overflow-hidden ${
        bgColor ? bgColor : 'bg-background1'
      }  ${className}`}
    >
      <Container className="min-h-[16rem]">
        <div className="flex flex-col md:flex-row  h-full  gap-10">
          <div className={`w-full md:w-1/2 ${image ? '' : 'mt-4 md:mt-20'}`}>
            <HeroTitle
              title={title}
              subtitle={subtitle}
              titleBlue={titleBlue}
            />

            {button && (
              <div className="flex flex-auto">
                <Link to={button.href} className="mt-14 flex flex-1">
                  <Button
                    variant="primary"
                    icon={button.icon}
                    className="flex  md:flex-none"
                  >
                    {button.label}
                  </Button>
                </Link>
              </div>
            )}
          </div>

          {image && (
            <div className="w-full md:w-1/2 flex justify-end items-center">
              {/* <div className="relative md:absolute mt-auto top-0 -right-10 sm:-right-20 md:right-auto bottom-0 flex w-full 2xl:w-[70rem] xl:w-[50rem] lg:w-[50rem] md:w-[40rem] h-full items-center"> */}
              {image}
              {/* </div> */}
            </div>
          )}
        </div>
      </Container>
    </section>
  );
};
