export interface Route {}

export const routes = {
  home: {
    to: '/',
    label: 'Domov',
  },
  aboutUs: {
    to: '/o-nas/',
    label: 'O nás',
  },
  services: {
    to: '/sluzby/',
    label: 'Služby',
  },
  privacyPolicy: {
    to: '/ochrana-osobnych-udajov/',
    label: 'Ochrana osobných údajov',
    hideInHeader: true,
  },
  cookies: {
    to: '/cookies-page',
    label: 'Súbory cookies',
    hideInHeader: true,
  },
  equipment: {
    to: '/vybavenie/',
    label: 'Vybavenie',
  },
  knowledgeShare: {
    to: '/oci/',
    label: 'Oči',
  },
  blog: {
    to: '/clanky/',
    label: 'Články',
  },
  contact: {
    to: '/kontakt/',
    label: 'Kontakt',
  },
};

export const generateArticleRoute = (slug: string): string => `/clanky/${slug}`;

export const generateKnowledgeBaseArticleRoute = (slug: string) =>
  `/oci/${slug}`;

export const generateServiceRoute = (slug: string) => `/sluzby/${slug}`;
