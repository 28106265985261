import * as React from 'react';

const SvgOrder = (props) => (
  <svg
    width={72}
    height={72}
    viewBox="0 0 72 72"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M21 17.25V2.25M51 17.25V2.25M17.25 32.25H54.75M9.75 69.75H62.25C66.3921 69.75 69.75 66.3921 69.75 62.25V17.25C69.75 13.1079 66.3921 9.75 62.25 9.75H9.75C5.60786 9.75 2.25 13.1079 2.25 17.25V62.25C2.25 66.3921 5.60786 69.75 9.75 69.75Z"
      stroke="#185FD4"
      strokeWidth={3}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default SvgOrder;
