import { Link } from 'gatsby';
import React from 'react';
import { KnowledgeShareItem, KnowledgeShareItemP } from './KnowledgeShareItem';

export interface KnowledgeShareSectionProps {
  className?: string;
  knowledgeItems: KnowledgeShareItemP[];
}

export const KnowledgeShareSection = (props: KnowledgeShareSectionProps) => {
  const { className = '', knowledgeItems } = props;
  return (
    <section
      {...props}
      className="grid grid-cols-1 md:grid-cols-2  lg:grid-cols-3 gap-12 py-10 md:py-20"
    >
      {knowledgeItems.map((item, index) => (
        <Link to={item.link} key={item.slug} className="w-full group">
          <KnowledgeShareItem {...item} className="group-hover:shadow-lg" />
        </Link>
      ))}
    </section>
  );
};
