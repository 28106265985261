import { FaFacebookF } from '@react-icons/all-files/fa/FaFacebookF';
import { FaLinkedinIn } from '@react-icons/all-files/fa/FaLinkedinIn';
import { FaRegEnvelope } from '@react-icons/all-files/fa/FaRegEnvelope';
import React from 'react';
import {
  EmailShareButton,
  FacebookShareButton,
  LinkedinShareButton,
} from 'react-share';

export interface ShareBlogActionsProps {}

export const ShareBlogActions = (props: ShareBlogActionsProps) => {
  const {} = props;
  const url = `${window.location}`;
  return (
    <div className="social lg:text-lg text-textTerciary opacity-40  space-x-4">
      <FacebookShareButton url={url}>
        <FaFacebookF />
      </FacebookShareButton>
      <EmailShareButton url={url}>
        <FaRegEnvelope />
      </EmailShareButton>
      <LinkedinShareButton url={url}>
        <FaLinkedinIn />
      </LinkedinShareButton>
    </div>
  );
};
