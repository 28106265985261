import { Button, Container } from '@abclinic/ui';
import { AiOutlineClose } from '@react-icons/all-files/ai/AiOutlineClose';
import { AiOutlineMenu } from '@react-icons/all-files/ai/AiOutlineMenu';
import { HiOutlineCalendar } from '@react-icons/all-files/hi/HiOutlineCalendar';
import clsx from 'clsx';
import { Link, useI18next } from 'gatsby-plugin-react-i18next';
import { useEffect, useState } from 'react';
import { routes } from '../../../../lib/routes';
import { LogoLink } from '../LogoLink/LogoLink';
import { MobileNavigation } from './MobileNavigation';
import { routes as webroutes } from '../../../../lib/routes';

import HamburgerMenu from 'react-hamburger-menu';

export interface HeaderNavigationProps {
  headerBtnType?: string;
  routes: {
    to: string;
    label: string;
  }[];
}

export const HeaderNavigation = (props: HeaderNavigationProps) => {
  const { routes: navigationRoutes = [], headerBtnType } = props;
  const [mobileMenuIsOpen, setMobileMenuIsOpen] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      if (mobileMenuIsOpen) {
        document.body.style.height = '100vh';
        document.body.style.overflowY = 'hidden';
      } else {
        document.body.style.height = '';
        document.body.style.overflowY = 'initial';
      }
    }, 230);
  }, [mobileMenuIsOpen]);

  const handleChangeMenuOpenedClicked = () => {
    setMobileMenuIsOpen(!mobileMenuIsOpen);
  };
  const { languages, originalPath } = useI18next();
  return (
    <Container className="flex justify-between">
      {/* LOGO  */}

      <div className="left flex items-center">
        <div className="logo">
          <LogoLink className="w-28 md:w-40" />
        </div>
      </div>

      {/* LINKS  */}
      <div className="hidden lg:flex center gap-x-2 md:gap-x-10 justify-center items-center text-sm w-[60%]">
        {navigationRoutes.map((route) => (
          <Link
            to={route.to}
            className={`text-textTerciary font-medium text-sm leading-4 `}
            activeClassName={`text-gray-800 font-medium `}
          >
            <span className="">{route.label}</span>
          </Link>
        ))}
      </div>

      {/* ORDER BUTTON  */}
      <div className="hidden lg:flex items-center justify-items-end  ">
        <Link to={routes.contact.to} className="block">
          <Button
            variant={`${headerBtnType || 'primary'}`}
            className="!rounded-2xl !text-sm"
            icon={<HiOutlineCalendar />}
          >
            Objednať sa
          </Button>
        </Link>
      </div>

      {/* MOBILE MENU ICON  */}
      <div className="lg:hidden relative z-[55] col-span-2 place-self-end self-center	">
        <HamburgerMenu
          isOpen={mobileMenuIsOpen}
          color={'#202335'}
          menuClicked={handleChangeMenuOpenedClicked}
          width={20}
          height={15}
          strokeWidth={2}
          animationDuration={0.23}
        />
        {/* <span className="" onClick={handleChangeMenuOpenedClicked}>
          {mobileMenuIsOpen ? (
            <AiOutlineClose className="text-2xl " />
          ) : (
            <AiOutlineMenu className="self-center lg:hidden text-2xl" />
          )}
        </span> */}
      </div>

      {/* MOBILE MENU  */}
      <MobileNavigation
        onLinkClicked={() => setMobileMenuIsOpen(false)}
        className={clsx({
          'translate-x-0 left-0': mobileMenuIsOpen,
          '-translate-x-full -left-full ': !mobileMenuIsOpen,
        })}
        routes={Object.values(navigationRoutes)}
      />
    </Container>
  );
};
