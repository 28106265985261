import React from 'react';

export interface BlogContainerProps {
  children?: React.ReactNode;
  className?: string;
}

export const BlogContainer = (props: BlogContainerProps) => {
  const { children, className } = props;
  return (
    <div className={`container lg:max-w-5xl mx-auto px-7 ${className}`}>
      {children}
    </div>
  );
};
