import React from 'react';
import { KnowledgeSharePage } from './model/knowledge-share-page.model';

export interface KnowledgeShareContextProviderProps {
  data: KnowledgeSharePage;
  children: React.ReactNode | React.ReactNode[];
}

const KnowledgeSharePageContext =
  React.createContext<KnowledgeSharePage | null>(null);

export const useKnowledgeSharePage = () => {
  const context = React.useContext(KnowledgeSharePageContext);
  if (context === null) {
    throw new Error(
      'useKnowledgeSharePage must be used within a KnowledgeSharePageProvider'
    );
  }
  return context;
};

export const KnowledgeShareContextProvider = (
  props: KnowledgeShareContextProviderProps
) => {
  const { data, children } = props;
  return (
    <KnowledgeSharePageContext.Provider value={data}>
      {children}
    </KnowledgeSharePageContext.Provider>
  );
};
