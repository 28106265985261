import { GrFacebookOption } from '@react-icons/all-files/gr/GrFacebookOption';
import { GrInstagram } from '@react-icons/all-files/gr/GrInstagram';
import { GrLinkedinOption } from '@react-icons/all-files/gr/GrLinkedinOption';
import React from 'react';
import { LogoLink } from '../../../LogoLink/LogoLink';
import { FooterSocialLink } from './FooterSocialLink';

export interface FooterCompanyIdentityProps {
  className?: string;
}

export const FooterCompanyIdentity = (props: FooterCompanyIdentityProps) => {
  const { className = '' } = props;
  return (
    <div {...props} className="flex flex-col justify-start">
      <LogoLink className="w-40 md:w-52" />
      {/* TODO:add socials */}
      {/* <div className="flex content-between gap-3 mt-6">
        <FooterSocialLink href="/" icon={<GrFacebookOption />} />
        <FooterSocialLink href="/" icon={<GrLinkedinOption />} />
        <FooterSocialLink href="/" icon={<GrInstagram />} />
      </div> */}
    </div>
  );
};
