import { Container } from '@abclinic/ui';
import { HiOutlineClock } from '@react-icons/all-files/hi/HiOutlineClock';
import { HiOutlineLocationMarker } from '@react-icons/all-files/hi/HiOutlineLocationMarker';
import { HiOutlinePhone } from '@react-icons/all-files/hi/HiOutlinePhone';
import { MdMailOutline } from '@react-icons/all-files/md/MdMailOutline';
import React from 'react';
import { ContactUsForm } from '../ContactUsForm/ContactUsForm';
import { useContactInfoItems } from '../../hooks/useContactInfoItems';
import { ContactInfos } from './ContactInfos';
import { t } from 'i18next';
import { useTranslation } from 'react-i18next';

export interface ContactFormWithContactInfoSectionProps {
  className?: string;
}

export const ContactFormWithContactInfoSection = (
  props: ContactFormWithContactInfoSectionProps
) => {
  const { className = '' } = props;

  const contactInfo = useContactInfoItems();
  const { t, i18n } = useTranslation(['common']);

  return (
    <section {...props} className={`${className}`}>
      <Container>
        <div className="flex flex-col gap-y-24  md:gap-y-32  lg:gap-0 lg:flex-row">
          {/* CONTACT FORM  */}
          <div className=" relative flex-1 flex flex-col drop-shadow-2xl rounded-[20px] bg-white px-7 py-8  md:p-10  xl:p-20">
            <header>
              <h2 className="text-titleText font-bold text-2xl md:text-4xl">
                {t('formTitle')}
              </h2>
              <p className="text-text text-sm md:text-lg leading-[164%] mt-3 md:mt-5">
                {t('formDescription')}
              </p>
            </header>

            <ContactUsForm formButtonPosition="right" />
          </div>

          {/* RIGHT INFO */}
          <div className="relative items-center flex flex-1 lg:flex-initial lg:w-96 xl:w-[29rem] bg-ultraUltra py-10  md:py-20  px-7 md:px-14 lg:my-16 rounded-2xl lg:rounded-l-none lg:rounded-r-2xl">
            <header>
              <h2 className="text-white text-2xl md:text-4xl font-bold">
                {t('weAreHereForYou')}
              </h2>
              <p className="text-white text-sm md:text-lg mt-3 font-extralight">
                {t('callUsTitle')}
              </p>
              <ContactInfos
                items={[
                  {
                    title: t('address') + ':',
                    icon: <HiOutlineLocationMarker />,
                    ...contactInfo.address,
                  },
                  {
                    title: t('officeHours') + ':',
                    icon: <HiOutlineClock />,
                    ...contactInfo.openingHours,
                  },
                  {
                    title: t('email') + ':',
                    icon: <MdMailOutline />,
                    ...contactInfo.email,
                  },
                  {
                    title: t('phone') + ':',
                    icon: <HiOutlinePhone />,
                    ...contactInfo.phone,
                  },
                ]}
              />
            </header>
          </div>
        </div>
      </Container>
    </section>
  );
};
