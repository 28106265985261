import * as React from 'react';

const SvgForm = (props) => (
  <svg
    width={56}
    height={72}
    viewBox="0 0 56 72"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M28 34.5C27.1716 34.5 26.5 35.1716 26.5 36C26.5 36.8284 27.1716 37.5 28 37.5V34.5ZM39.25 37.5C40.0784 37.5 40.75 36.8284 40.75 36C40.75 35.1716 40.0784 34.5 39.25 34.5V37.5ZM28 49.5C27.1716 49.5 26.5 50.1716 26.5 51C26.5 51.8284 27.1716 52.5 28 52.5V49.5ZM39.25 52.5C40.0784 52.5 40.75 51.8284 40.75 51C40.75 50.1716 40.0784 49.5 39.25 49.5V52.5ZM16.75 34.5C15.9216 34.5 15.25 35.1716 15.25 36C15.25 36.8284 15.9216 37.5 16.75 37.5V34.5ZM16.7875 37.5C17.6159 37.5 18.2875 36.8284 18.2875 36C18.2875 35.1716 17.6159 34.5 16.7875 34.5V37.5ZM16.75 49.5C15.9216 49.5 15.25 50.1716 15.25 51C15.25 51.8284 15.9216 52.5 16.75 52.5V49.5ZM16.7875 52.5C17.6159 52.5 18.2875 51.8284 18.2875 51C18.2875 50.1716 17.6159 49.5 16.7875 49.5V52.5ZM52.75 17.25V62.25H55.75V17.25H52.75ZM46.75 68.25H9.25V71.25H46.75V68.25ZM3.25 62.25V17.25H0.25V62.25H3.25ZM9.25 11.25H16.75V8.25H9.25V11.25ZM39.25 11.25H46.75V8.25H39.25V11.25ZM9.25 68.25C5.93629 68.25 3.25 65.5637 3.25 62.25H0.25C0.25 67.2206 4.27944 71.25 9.25 71.25V68.25ZM52.75 62.25C52.75 65.5637 50.0637 68.25 46.75 68.25V71.25C51.7206 71.25 55.75 67.2206 55.75 62.25H52.75ZM55.75 17.25C55.75 12.2794 51.7206 8.25 46.75 8.25V11.25C50.0637 11.25 52.75 13.9363 52.75 17.25H55.75ZM3.25 17.25C3.25 13.9363 5.93629 11.25 9.25 11.25V8.25C4.27944 8.25 0.25 12.2794 0.25 17.25H3.25ZM28 37.5H39.25V34.5H28V37.5ZM28 52.5H39.25V49.5H28V52.5ZM24.25 3.75H31.75V0.75H24.25V3.75ZM31.75 15.75H24.25V18.75H31.75V15.75ZM24.25 15.75C20.9363 15.75 18.25 13.0637 18.25 9.75H15.25C15.25 14.7206 19.2794 18.75 24.25 18.75V15.75ZM37.75 9.75C37.75 13.0637 35.0637 15.75 31.75 15.75V18.75C36.7206 18.75 40.75 14.7206 40.75 9.75H37.75ZM31.75 3.75C35.0637 3.75 37.75 6.43629 37.75 9.75H40.75C40.75 4.77944 36.7206 0.75 31.75 0.75V3.75ZM24.25 0.75C19.2794 0.75 15.25 4.77944 15.25 9.75H18.25C18.25 6.43629 20.9363 3.75 24.25 3.75V0.75ZM16.75 37.5H16.7875V34.5H16.75V37.5ZM16.75 52.5H16.7875V49.5H16.75V52.5Z"
      fill="#185FD4"
    />
  </svg>
);

export default SvgForm;
