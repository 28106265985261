import React from 'react';

export interface StaffMemberProps {
  name: string;
  position: string;
  description: string;
  image: React.ReactNode;
}

export const StaffMember = (props: StaffMemberProps) => {
  const { name, description, position, image } = props;
  return (
    <article className="flex flex-col lg:flex-row gap-y-5  gap-x-8 items-center lg:items-start md:-ml-2">
      <div className="md:mx-auto w-32 h-32 overflow-hidden rounded-full">
        {image}
      </div>
      <div className="flex-1 flex flex-col text-center md:text-left">
        <div className="mb-2">
          <h3 className="text-terciary text-lg md:text-2xl font-bold">
            {name}
          </h3>
          <span className="text-sm md:text-base text-primary">{position}</span>
        </div>
        <p className="text-sm md:text-base text-opacity-50">{description}</p>
      </div>
    </article>
  );
};
