import React from 'react';

export interface OpeningHoursItemProps {
  to: string;
  from: string;
  day: string;
}

export const OpeningHoursItem = (props: OpeningHoursItemProps) => {
  const { to, from, day } = props;

  const todaySlovak = new Date().toLocaleString('sk-SK', {
    weekday: 'long',
  });

  const isToday = todaySlovak === day.toLowerCase();

  return (
    <div
      className={`flex justify-between ${
        isToday ? 'font-semibold text-primary' : ''
      }`}
    >
      <div className="day mr-8 lg:mr-12 text-sm md:text-base">{day}</div>
      <div className={`time flex`}>
        <span className="from text-sm md:text-base w-12 text-left">
          {new Date('1970-01-01T' + from)
            .toLocaleTimeString('sk', {
              hour: '2-digit',
              minute: '2-digit',
            })
            // slice is necessary because, for a second it flasshes AM or PM
            // looks like locale is not loaded properly or something
            .slice(0, 5)}
        </span>
        <span className="text-sm md:text-base w-4">-</span>
        <span className="to text-sm md:text-base w-12">
          {new Date('1970-01-01T' + to)
            .toLocaleTimeString('sk', {
              hour: '2-digit',
              minute: '2-digit',
            })
            .slice(0, 5)}
        </span>
      </div>
    </div>
  );
};
