import { Button, Container, HeroProps } from '@abclinic/ui';
import { HiOutlineClock } from '@react-icons/all-files/hi/HiOutlineClock';
import { HiOutlineLocationMarker } from '@react-icons/all-files/hi/HiOutlineLocationMarker';
import { HiOutlineMail } from '@react-icons/all-files/hi/HiOutlineMail';
import { HiOutlinePhone } from '@react-icons/all-files/hi/HiOutlinePhone';
import { Link } from 'gatsby';
import { useContactInfoItems } from '../../Shared/hooks/useContactInfoItems';
import { ContactInfoCard } from './ContactInfoCard';

export interface HomeHeroProps extends HeroProps {
  className?: string;
}

export const HomeHero = (props: HomeHeroProps) => {
  const {
    className = '',
    title,
    subtitle,
    button,
    image,
    rightBgColor,
  } = props;

  const contactInfo = useContactInfoItems();

  return (
    <section
      className={`pt-36 md:h-screen flex items-center min-h-[48rem] relative md:bg-background1 w-screen  md:mb-20 ${className}`}
    >
      <Container>
        <div className="flex flex-col md:flex-row  h-full  md:w-1/2  ">
          {rightBgColor && (
            <div
              className={`md:w-[12rem] lg:w-[20rem] xl:w-[26rem] 2xl:w-[32%] md:h-full absolute right-0 bottom-0 md:top-0 bg-${rightBgColor}`}
            />
          )}
          <div className="w-full z-30 mt-10 md:mt-0">
            <h1 className="text-titleText text-4xl md:text-6xl lg:text-7xl leading-[116%] font-bold">
              {title}
            </h1>

            {subtitle && (
              <p className="font-extralight text-base md:text-xl text-text opacity-[54%] mt-8">
                {subtitle}
              </p>
            )}

            {button && (
              <Link to={button.href} className="mt-16 block">
                <Button variant="primary" icon={button.icon}>
                  {button.label}
                </Button>
              </Link>
            )}
          </div>

          <div className="right-0 left-0 flex absolute bottom-0 h-screen  w-screen">
            <div className="h-full absolute  bottom-0 left-auto sm:right-[0rem] md:right-[0rem] 2xl:right-[18rem] xl:right-[12rem] flex w-0 md:w-[30rem]  xl:w-[28rem] 2xl:w-[32rem]  items-center">
              {image}
            </div>
          </div>
          <div className=" mt-24 relative md:absolute md:bottom-0  left-auto 2xl:right-56  md:-right-3 lg:md:right-28 z-0">
            <div className=" w-full absolute -top-8 z-0 h-40 bg-ultraUltra md:hidden rounded-2xl"></div>
            <div className=" px-6 md:px-6 mb-2 relative -top-2 z-10 ">
              <ContactInfoCard
                title={'Rýchly kontakt'}
                items={[
                  {
                    icon: <HiOutlineLocationMarker />,
                    ...contactInfo.address,
                  },
                  {
                    icon: <HiOutlineClock />,
                    ...contactInfo.openingHours,
                  },
                  {
                    icon: <HiOutlineMail />,
                    ...contactInfo.email,
                  },
                  {
                    icon: <HiOutlinePhone />,
                    ...contactInfo.phone,
                  },
                ]}
              />
            </div>
          </div>
        </div>
      </Container>
    </section>
  );
};
