import { graphql, useStaticQuery } from 'gatsby';

export const useContactInfoItems = () => {
  const {
    strapiGlobal: { contactInfo },
  } = useStaticQuery(graphql`
    query ContactInfoItems {
      strapiGlobal {
        contactInfo {
          phone {
            label
            href
            sublabel
          }
          address {
            label
            href
            sublabel
          }
          openingHours {
            label
            href
            sublabel
          }
          email {
            label
            href
            sublabel
          }
        }
      }
    }
  `);
  return contactInfo;
};
