import React from "react";
import { IQuoteBlock } from "../model/editorjs";

export type QuoteBlockProps = IQuoteBlock

export const QuoteBlock = (props: QuoteBlockProps) => {
  const { data: { alignment, caption, text } } = props;
  return (
    <section className=" w-full md:w-4/6 mx-auto py-12 md:py-24">
      <blockquote className={`${alignment === "left" ? "text-left" : "text-center"} lg:text-3xl text-xl`}>
        {text}
      </blockquote>
      {caption && (
        <cite className="text-textSecondary mt-2 flex">{caption}</cite>
      )}
    </section>
  );
};
