/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it
import '@fontsource/poppins'; // Defaults to weight 400 with all styles included.

import '@fontsource/poppins/100.css'; // Weight 500 with all styles included.
import '@fontsource/poppins/200.css'; // Weight 500 with all styles included.
import '@fontsource/poppins/300.css'; // Weight 500 with all styles included.
import '@fontsource/poppins/400.css'; // Weight 500 with all styles included.
import '@fontsource/poppins/500.css'; // Weight 500 with all styles included.
import '@fontsource/poppins/600.css'; // Weight 500 with all styles included.
import '@fontsource/poppins/800.css'; // Weight 500 with all styles included.
import '@fontsource/poppins/700.css'; // Weight 500 with all styles included.
