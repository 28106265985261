
export const FooterBottomInfo = () => {
  return (
    <p className="text-center py-7 text-sm space-x-1 text-secondary ">
      <span className=" opacity-50 font-light">
        Design aj vyhotovenie stránky zastrešila spoločnosť
      </span>
      <a
        href="https://www.momentumdigital.sk/?utm_source=abelovska.sk&utm_medium=clients&utm_campaign=clients"
        rel="noreferrer"
        target={'_blank'}
        className="opacity-70 font-medium"
      >
        {' Momentum Digital s.r.o.'}
      </a>
    </p>
  );
};
