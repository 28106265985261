import { Button, Checkbox, Input, TextArea } from '@abclinic/ui';
import { FaTelegramPlane } from '@react-icons/all-files/fa/FaTelegramPlane';
import clsx from 'clsx';
import { Link, useTranslation } from 'gatsby-plugin-react-i18next';
import React from 'react';
import { useForm } from 'react-hook-form';
import { Portal } from 'react-portal';
import { useMutation } from 'react-query';
import { emailRegex } from '../../../../../lib/consts';
import { submitForm } from '../../../../../lib/features/form/form.api';
import { routes } from '../../../../../lib/routes';
import { SuccessModal } from '../../SuccessModal/SuccessModal';

export interface ContactFormProps {
  className?: string;
  buttonPosition?: 'left' | 'right' | 'center';
}

export const ContactForm = (props: ContactFormProps) => {
  const { className, buttonPosition } = props;
  const [modalOpen, setModalOpen] = React.useState(false);

  const { control, handleSubmit, reset, register } = useForm({
    mode: 'onBlur',
  });

  const { mutate, isLoading } = useMutation(submitForm, {
    onSuccess: () => {
      setModalOpen(true);
      reset();
    },
  });

  const onSubmit = (data: any) => {
    mutate(data);
  };

  const { t } = useTranslation(['common']);

  return (
    <section className={`mt-4 md:mt-12`}>
      <Portal>
        <SuccessModal
          open={modalOpen}
          onClose={() => {
            setModalOpen(false);
          }}
        />
      </Portal>

      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="form-items grid grid-cols-2 md:gap-x-12 gap-y-7 w-full border-none pt-2">
          <Input
            control={control}
            name={'name'}
            label={t('name')}
            type={'text'}
            placeholder={t('fullName')}
            className={'col-span-2 md:col-span-1'}
            rules={{
              required: {
                value: true,
                message: t('validation.fieldIsRequired'),
              },
            }}
          />
          <Input
            name={'email'}
            control={control}
            label={t('Email')}
            type={'email'}
            placeholder={'moje.meno@gmail.com'}
            className={'col-span-2 md:col-span-1'}
            rules={{
              pattern: {
                value: emailRegex,
                message: t('validation.typeValidEmail'),
              },
              required: {
                value: true,
                message: t('validation.fieldIsRequired'),
              },
            }}
          />
          <TextArea
            control={control}
            rules={{
              required: {
                value: true,
                message: t('validation.fieldIsRequired'),
              },
            }}
            label={t('howCanWeHelp')}
            name={'message'}
            placeholder={t('whatCouldweHelpYouWith')}
            className={'col-span-2'}
          />
          <Checkbox
            name={'agreement'}
            control={control}
            className={'col-span-2'}
            rules={{
              validate: (value: boolean) =>
                value ? undefined : t('youMustAgreeWithProcessingData'),
            }}
            label={
              <div>
                {t('bySendingRequestYouAgree')}
                <Link
                  to={routes.privacyPolicy.to}
                  className="text-primary ml-1"
                >
                  {routes.privacyPolicy.label}
                </Link>
                .
              </div>
            }
          />
          <div
            className={clsx({
              'actions mt-4 md:mt-8 col-span-2 flex': true,
              'justify-start': buttonPosition === 'left',
              'justify-center': buttonPosition === 'center',
              'justify-end': buttonPosition === 'right',
            })}
          >
            <Button
              type="submit"
              variant="primary"
              className="!px-14 flex flex-1 lg:flex-none"
              icon={<FaTelegramPlane />}
              loading={isLoading}
            >
              <span>{t('send')}</span>
            </Button>
          </div>
        </div>
      </form>
    </section>
  );
};
