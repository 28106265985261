import { BlogSection, BlogSectionProps, Button } from '@abclinic/ui';
import { routes } from '../../../lib/routes';
import React from 'react';
import { useFeaturedArticles } from './hooks/useLatestArticles';
import { Link } from 'gatsby-plugin-react-i18next';

export type HomeBlogSectionProps = BlogSectionProps;

export const HomeBlogSection = (props: HomeBlogSectionProps) => {
  const { className = '', ...restOfProps } = props;
  const articles = useFeaturedArticles();

  return (
    <div>
      <BlogSection {...restOfProps} />
      <div className="flex justify-center mt-12 md:mt-16">
        <Link to={routes.blog.to}>
          <Button variant="primary">Všetky články</Button>
        </Link>
      </div>
    </div>
  );
};
