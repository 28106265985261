import React, { useMemo } from 'react';
import { ITableBlock } from '../model/editorjs';

export type TableBlockProps = ITableBlock;

export const TableBlock = (props: TableBlockProps) => {
  const {
    data: { content, withHeadings },
  } = props;

  const [header, rows] = useMemo(() => {
    if (withHeadings) {
      const [header, ...rows] = content;
      return [header, rows];
    } else {
      return [undefined, content];
    }
  }, [content, withHeadings]);

  return (
    <section className="flex justify-center text-sm md:text-lg py-12 md:py-24">
      <div className="wrapper rounded-2xl border border-zinc-100 shadow-xl p-4 md:p-8 text-left max-w-[100vw] overflow-x-scroll">
        <table className="table-auto">
          {header && (
            <thead>
              <tr className="border-b">
                {header.map((cell, index) => (
                  <th className="py-3 px-6" key={index}>
                    {cell}
                  </th>
                ))}
              </tr>
            </thead>
          )}
          <tbody>
            {rows.map((row, index) => (
              <tr className="border-b" key={index}>
                {row.map((cell, index) => (
                  <td className="py-1.5 px-6 font-light" key={index}>
                    {cell}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </section>
  );
};
