import { graphql, useStaticQuery } from 'gatsby';

export const useStaffMembers = () => {
  const {
    allStrapiStaffMember: { nodes: staffMembers },
  } = useStaticQuery(
    graphql`
      query StaffMembers {
        allStrapiStaffMember {
          nodes {
            description
            name
            position
            id
            image {
              localFile {
                childImageSharp {
                  gatsbyImageData
                }
              }
            }
          }
        }
      }
    `
  );
  return staffMembers;
};
