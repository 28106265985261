import { Button, Card } from '@abclinic/ui';
import { Link } from 'gatsby';
import React from 'react';

export interface CtaBannerProps {
  title: string;
  alternativeTitle?: string;
  content: string;
  image?: React.ReactNode;
  button?: {
    label: string;
    href: string;
    icon?: React.ReactNode;
  };
}

export const CtaBanner = (props: CtaBannerProps) => {
  const { title, alternativeTitle, content, button, image } = props;
  return (
    <article
      className={`flex flex-col md:flex-row items-center bg-white rounded-3xl shadow-2xl md:pt-9 pb-9 gap-x-8 overflow-hidden text-center md:text-left`}
    >
      {image && (
        <div className="w-full flex justify-end md:justify-start md:w-1/3 min-w-[20rem] ">
          {image}
        </div>
      )}

      <div
        className={`md:w-2/3  md:pr-16 px-8 md:px-0  ${
          !image && 'py-9 !w-full !px-8 !md:px-16'
        }`}
      >
        <h3 className="font-semibold text-2xl md:text-3xl lg:text-3xl ">
          {title} <span className="text-primary">{alternativeTitle}</span>
        </h3>
        <p className="mt-4 font-light text-base lg:text-xl">{content}</p>

        <Link to={button.href} className="mt-12 flex">
          <Button
            variant="primary"
            icon={button.icon}
            className="flex flex-1 md:flex-none"
          >
            {button.label}
          </Button>
        </Link>
      </div>
    </article>
  );
};
