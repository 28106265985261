import { Container } from "@abclinic/ui"
import { HiOutlinePhone } from "@react-icons/all-files/hi/HiOutlinePhone"
import { MdMailOutline } from "@react-icons/all-files/md/MdMailOutline"
import { Link, useI18next, useTranslation } from "gatsby-plugin-react-i18next"

import { useContactInfoItems } from "../../../Shared/hooks/useContactInfoItems"
import { FooterBottomInfo } from "./components/FooterBottomInfo/FooterBottomInfo"
import { FooterCompanyIdentity } from "./components/FooterCompanyIdentity/FooterCompanyIdentity"
import { FooterLinkSection } from "./components/FooterLinkSection/FooterLinksSection"

export interface FooterProps {
  className?: string;
}

export const Footer = (props: FooterProps) => {
  const { className = '' } = props;
  const { languages, originalPath } = useI18next();
  const contactInfo = useContactInfoItems();
  const { t, i18n } = useTranslation(['common']);
  const contactsArray = [
    {
      icon: <MdMailOutline />,
      ...contactInfo.email,
    },
    {
      icon: <HiOutlinePhone />,
      ...contactInfo.phone,
    },
  ];

  return (
    <footer {...props} className={`pt-20 lg:pt-60 ${className}`}>
      <Container>
        <div className="flex flex-col md:flex-row justify-between gap-10 md:gap-20">
          <FooterCompanyIdentity />

          {/* <ul className="languages">
            {languages.map((lng) => (
              <li key={lng}>
                <Link to={originalPath} language={lng}>
                  {lng}
                </Link>
              </li>
            ))}
          </ul> */}

          <section className="grid grid-cols-1 sm:grid-cols-3 gap-10 sm:gap-4 md:gap-8 lg:gap-12 xl:gap-16 items-start">
            <FooterLinkSection
              title={'Navigácia'}
              links={[
                {
                  href: '/',
                  title: 'Domov',
                },
                {
                  title: 'O nás',
                  href: '/o-nas/',
                },
                {
                  title: 'Služby',
                  href: '/sluzby/',
                },
                {
                  href: '/vybavenie/',
                  title: 'Vybavenie',
                },
                {
                  title: 'Oči',
                  href: '/oci/',
                },
              ]}
            />

            <FooterLinkSection
              title={'Informácie'}
              links={[
                {
                  title: 'Články',
                  href: '/clanky/',
                },
                {
                  title: 'Kontakt',
                  href: '/kontakt/',
                },
                {
                  href: '/ochrana-osobnych-udajov',
                  title: 'Informácie GDPR',
                },
                {
                  href: '/cookies',
                  title: 'Súbory cookies',
                },
              ]}
            />
            <div>
              <FooterLinkSection title={'Kontakt'} links={[]} />
              <div>
                {contactsArray.map((item) => (
                  <a href={item.href} rel="noopener noreferrer">
                    <article className="flex flex-col gap-3 mt-3">
                      <p className="flex space-x-3 text-base lg:text-md items-center text-textTerciary font-light hover:opacity-70">
                        <div className="text-2xl">{item.icon}</div>
                        <div>{item.label}</div>
                      </p>
                    </article>
                  </a>
                ))}
              </div>
            </div>
          </section>
        </div>
        <section className=" pt-8 md:pt-16">
          <FooterBottomInfo />
        </section>
      </Container>
    </footer>
  );
};
