import { EditorJsBody, StrapiSeo } from "@abclinic/ui"
import { graphql } from "gatsby"
import { useMemo } from "react"

import { Layout } from "../components/Layout/Layout"

export interface CookiesProps {
  data: any;
}

export const query = graphql`
  query CookiesPageQuery {
    strapiCookiesPage {
      content {
        data {
          content
        }
      }
      seo {
        ...SeoFragment
      }
    }
  }
`;

export const CookiesPage = (props: CookiesProps) => {
  const { data } = props;

  const body = useMemo(() => {
    const da = data.strapiCookiesPage.content.data.content;
    return JSON.parse(da).blocks;
  }, [data]);

  return (
    <Layout fullHeader>
      <StrapiSeo seo={data.strapiCookiesPage.seo} />
      <EditorJsBody blocks={body} />
    </Layout>
  );
};

export default CookiesPage;
