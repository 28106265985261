import React from "react";
import { IRawBlock } from "../model/editorjs";

export type RawBlockProps = IRawBlock

export const RawBlock = (props: RawBlockProps) => {
  const { data: { html } } = props;
  return (
    <div className="flex justify-center !my-16 rounded-3xl overflow-hidden">
      <div dangerouslySetInnerHTML={{ __html: html }} />
    </div>
  )
};
