import * as React from 'react';

const SvgMap = (props) => (
  <svg
    width={78}
    height={70}
    viewBox="0 0 78 70"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M26.75 67.6668L4.50721 56.5454C3.12384 55.8538 2.25 54.4398 2.25 52.8932V8.94047C2.25 5.90499 5.44444 3.93072 8.15945 5.28822L26.75 14.5835M26.75 67.6668L51.25 55.4168M26.75 67.6668V14.5835M51.25 55.4168L69.8406 64.7121C72.5556 66.0696 75.75 64.0953 75.75 61.0599V17.1071C75.75 15.5605 74.8762 14.1466 73.4928 13.4549L51.25 2.3335M51.25 55.4168V2.3335M51.25 2.3335L26.75 14.5835"
      stroke="#185FD4"
      strokeWidth={3}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default SvgMap;
