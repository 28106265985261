import React from 'react';
export interface TechnologieItemProps {
  className?: string;
  title: {
    bold: string;
    normal: string;
  };
  subtitle: string;
  image: React.ReactNode;
}

export const TechnologieItem = (props: TechnologieItemProps) => {
  const { title, image, subtitle, className } = props;
  return (
    <article
      className={`w-[47%] sm:w-[272px] md:w-72 lg:w-2/5 h-[260px] sm:h-[353px] bg-white rounded-2xl shadow-xl relative flex flex-col justify-end overflow-hidden ${className}`}
    >
      <div className="image w-full">{image}</div>

      <header className="mx-3 sm:mx-7 mb-8">
        <h2 className="font-bold text-black text-sm md:text-lg">
          {title.bold} <span className="font-light">{title.normal}</span>
        </h2>
        <p className="font-extralight text-[0.65rem] leading-[0.7rem] sm:text-xs text-black">
          {subtitle}
        </p>
      </header>
    </article>
  );
};
