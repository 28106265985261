import { Card } from '@abclinic/ui';
import { Link } from 'gatsby';
import React from 'react';
import SvgForm from '../assets/icons/Form';
import SvgKnowledgeshare from '../assets/icons/Knowledgeshare';
import SvgMap from '../assets/icons/Map';
import SvgOrder from '../assets/icons/Order';

type DestinationType = 'map' | 'order' | 'form' | 'knowledgeshare';

export interface QuickNavigationCardProps {
  className?: string;
  title: string;
  subtitle: string;
  linkTo: string;
  type: DestinationType;
}

const navigationDestinationTypeIconMap: Record<
  DestinationType,
  React.ReactNode
> = {
  form: <SvgForm />,
  order: <SvgOrder />,
  map: <SvgMap />,
  knowledgeshare: <SvgKnowledgeshare />,
};

export const QuickNavigationCard = (props: QuickNavigationCardProps) => {
  const { className = '', title, subtitle, linkTo, type } = props;
  return (
    <Link to={linkTo} {...props}>
      <Card className="p-6 md:p-10 flex flex-col justify-center items-center w-full hover:drop-shadow-xl transition-all ease-in-out duration-300">
        {/* ICON */}
        <div className="icon h-6 w-6 md:h-8 md:w-8 lg:h-16 lg:w-16 flex justify-center items-center">
          {navigationDestinationTypeIconMap[type]}
        </div>

        {/* TEXT */}
        <h3 className="font-semibold lg:font-bold text-base md:text-lg leading-5 text-black text-center mt-3 sm:mt-5 md:mt-8">
          {title}
        </h3>
        <p className="text-xs md:text-sm font-light text-text mt-1 sm:mt-3 text-center flex items-center">
          {subtitle}
        </p>
      </Card>
    </Link>
  );
};
