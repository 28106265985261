import { Button, Card } from '@abclinic/ui';
import { Link } from 'gatsby';
import {
  GatsbyImage,
  getImage,
  IGatsbyImageData,
  StaticImage,
} from 'gatsby-plugin-image';
import React from 'react';

export interface HomeTestimonialCardProps {
  className?: string;
  title: string;
  content: string;
  patientName?: string;
  patientPosition?: string;
  buttonTitle?: string;
  buttonHref: string;
  image: IGatsbyImageData;
}

export const HomeTestimonialCard = (props: HomeTestimonialCardProps) => {
  const {
    title,
    content,
    patientName,
    patientPosition,
    buttonTitle,
    buttonHref,
    image,
  } = props;

  return (
    <Card className="my-11 flex flex-col md:flex-row justify-between relative">
      {/* IMAGE */}
      <div className="card-image w-full overflow-hidden relative md:w-1/3">
        <GatsbyImage
          className="h-96 md:h-full"
          imgClassName="rounded-t-2xl overflow-hidden md:rounded-tr-none md:rounded-l-2xl"
          image={getImage(image?.localFile?.childImageSharp.gatsbyImageData)}
          alt={title}
        />
      </div>

      {/* CONTENT */}
      <div className="card-content justify-between p-7 px-6 md:p-12 lg:px-20 flex flex-col  flex-initial relative overflow-hidden md:w-2/3">
        <section>
          <header>
            <span className="text-primary opacity-50 font-light leading-[116%] text-sm md:text-base lg:text-lg">
              Príbeh pacienta
            </span>
            <h2 className="mt-1 md:mt-6 text-terciary  text-2xl md:text-3xl lg:text-4xl font-bold">
              {title}
            </h2>
          </header>
          <article className="pt-6 pb-6 md:pb-12">
            <p className="leading-5 lg:leading-7 text-sm md:text-base lg:text-lg font-light">
              {content}
            </p>
          </article>
        </section>

        <footer>
          <div className="flex justify-between flex-col md:flex-row gap-6">
            <article>
              <div className="font-medium">{patientName}</div>
              <div className="text-gray-400">{patientPosition}</div>
            </article>
            {buttonTitle && (
              <Link to={buttonHref} className="!z-50">
                <Button variant="primary" icon={true}>
                  {buttonTitle}
                </Button>
              </Link>
            )}
          </div>
        </footer>

        <div className="w-56 h-24 z-20 bottom-0 -right-14 md:-right-16 absolute opacity-70">
          <StaticImage
            className="relative"
            placeholder="tracedSVG"
            quality={35}
            src={'./../../../assets/quote.svg'}
            alt={'Testimonial quote image'}
            imgClassName="h-full w-full relative"
          />
        </div>
      </div>
    </Card>
  );
};
