import { Button, Container } from '@abclinic/ui';
import { Link } from 'gatsby';
import React from 'react';
import { Layout } from '../components/Layout/Layout';
import { routes } from '../lib/routes';

function refreshPage() {
  window.location.reload();
}

const NotFoundPage = () => (
  <Layout>
    <section className="py-32 md:py-64">
      <Container>
        <h1 className="justify-center flex flex-col items-center font-bold   text-center">
          <span className="flex text-primary text-[5rem] md:text-[10rem]">
            500
          </span>
          <span className="flex text-text font-semibold text-[1.5rem] md:text-[3rem]">
            Niečo nie je v poriadku...
          </span>
          <span className="mt-4 mb-8 font-light text-sm md:text-base">
            Nepodarilo sa načítať stránku, ktorú hľadáte. Prosím, buďte
            trpezlivý alebo skúste načítať stránku neskôr.
          </span>
          <Button variant="primary" onClick={refreshPage}>
            Refresh
          </Button>
        </h1>
      </Container>
    </section>
  </Layout>
);

export default NotFoundPage;
