import { StaticImage } from 'gatsby-plugin-image';
import React from 'react';
import { IframeSection } from '../../Shared/components/IframeSection/IframeSection';

export const LocationStreetview = () => {
  return (
    <IframeSection
      header={{
        title: 'Kde nás najdete',
        subtitle:
          'Ambulancia sa nachádza v centre mesta, v blízkosti námestia SNP. Nájdete nás na ulici Dukelských hrdinov číslo 16, na prvom poschodí.',
      }}
      embedUrl="https://www.google.com/maps/embed?pb=!4v1671115599914!6m8!1m7!1sKKeTC_Ph-qyZQURbWIInww!2m2!1d48.58103369628262!2d19.12665479984238!3f50.35166788211234!4f2.395105937398995!5f0.7820865974627469&streetViewControl=false"
    />
  );
};
