import { BlogCardProps } from '@abclinic/ui';
import { graphql, useStaticQuery } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { generateArticleRoute } from '../../../../lib/routes';
import { getFirstNWords } from '../../../../lib/utils/getFirstNWords';

export const useFeaturedArticles = (): BlogCardProps[] => {
  const data = useStaticQuery(graphql`
    query getFeaturedArticles {
      allStrapiArticle {
        nodes {
          title
          slug
          excerpt
          author {
            name
          }
          image {
            alternativeText
            localFile {
              childImageSharp {
                gatsbyImageData(
                  width: 500
                  webpOptions: {}
                  quality: 50
                  layout: FULL_WIDTH
                  placeholder: BLURRED
                )
              }
            }
          }
        }
      }
    }
  `);

  return data.allStrapiArticle.nodes.map((article: any) => ({
    author: article.author?.name,
    title: article.title,
    perex: getFirstNWords(article.excerpt, 8),
    route: generateArticleRoute(article.slug),
    slug: article.slug,
    image: (
      <GatsbyImage
        className="w-full h-full"
        image={getImage(
          article.image.localFile.childImageSharp.gatsbyImageData
        )}
        imgClassName="rounded-xl overflow-hidden"
        alt={article.image.alternativeText}
      />
    ),
  }));
};
