import React from 'react';

export interface NoticeProps {
  title: string;
  slug: string;
  excerpt: string;
}

export const Notice = (props: NoticeProps) => {
  const { title, slug, excerpt } = props;
  return (
    <article>
      <header className='mb-3'>
        <h3 className="text-base md:text-xl text-terciary font-semibold">{title}</h3>
      </header>
      <main>
        <p className="font-normal text-sm md:text-base md:leading-8 text-black">{excerpt}</p>
      </main>
    </article>
  );
};
