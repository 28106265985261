import React from 'react';
import { QueryClient, QueryClientProvider } from 'react-query';
import { Footer } from './components/Footer/Footer';
import { Header } from './components/Header/Header';
import { MaintenanceMode } from './components/MaintenanceMode';
import './styles/global.scss';

export interface LayoutProps {
  children?: React.ReactNode | React.ReactNodeArray;
  fullHeader?: boolean;
  headerBtnType?: string;
}
export const queryClient = new QueryClient();

export const Layout = (props: LayoutProps) => {
  const { fullHeader, headerBtnType, ...restOfProps } = props;

  // if (process.env.GATSBY_MAINTENANCE === 'true') {
  //   return <MaintenanceMode />;
  // }

  return (
    <QueryClientProvider client={queryClient}>
      <Header full={fullHeader} headerBtnType={headerBtnType} />
      <main>{props.children}</main>
      <Footer />
    </QueryClientProvider>
  );
};
