import { EditorJsBody, StrapiSeo } from '@abclinic/ui';
import { graphql } from 'gatsby';
import { useMemo } from 'react';
import { Layout } from '../components/Layout/Layout';

export interface PrivacyPolicyProps {
  data: any;
}

export const query = graphql`
  query PrivacyPolicyPageQuery {
    strapiPrivacyPolicyPage {
      content {
        data {
          content
        }
      }
      seo {
        ...SeoFragment
      }
    }
  }
`;

export const PrivacyPolicyPage = (props: PrivacyPolicyProps) => {
  const { data } = props;

  const body = useMemo(() => {
    const da = data.strapiPrivacyPolicyPage.content.data.content;
    return JSON.parse(da).blocks;
  }, [data]);

  return (
    <Layout fullHeader>
      <StrapiSeo seo={data.strapiPrivacyPolicyPage.seo} />
      <EditorJsBody blocks={body} />
    </Layout>
  );
};

export default PrivacyPolicyPage;
