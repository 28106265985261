import React from 'react';
import { IListBlock } from '../model/editorjs';

export type ListBlockProps = IListBlock;

export const ListBlock = (props: ListBlockProps) => {
  const {
    data: { items, style },
  } = props;

  switch (style) {
    case 'unordered':
      return (
        <ul className="list-disc pl-6 space-y-2">
          {items.map((item, index) => (
            <li key={index} dangerouslySetInnerHTML={{ __html: item }} />
          ))}
        </ul>
      );
    case 'ordered':
      return (
        <ol className="list-decimal pl-6 space-y-2">
          {items.map((item, index) => (
            <li key={index} dangerouslySetInnerHTML={{ __html: item }} />
          ))}
        </ol>
      );
  }
};
