import { Button } from '@abclinic/ui';
import React from 'react';
import { Article } from '../../../model/article.model';

export interface BlogCardProps extends Article {
  className?: string;
}

export const BlogCard = (props: BlogCardProps) => {
  const { author, title, perex, image } = props;
  return (
    <article className="p-5 flex flex-col justify-between rounded-2xl bg-white group hover:shadow-2xl shadow-lg h-full">
      {/* Image */}
      <div className="relative aspect-[4/3] flex w-full items-center overflow-hidden rounded-2xl">
        {image}
      </div>

      {/* Header */}
      <div className="content p-1 mt-2 flex flex-col justify-between flex-1">
        <div className="body">
          <header>
            <p className="font-normal text-text text-opacity-40 text-xs ">
              {author}
            </p>
            <h3 className="text-terciary font-semibold text-2xl leading-7 mt-2">
              {title}
            </h3>
          </header>

          {/* Text */}
          <p className="mt-5 text-text font-light leading-6 text-base inline-block">
            {perex}
          </p>
        </div>
        <div className="actions mt-4 md:mt-8">
          <Button
            variant="transparent"
            icon
            className={
              'flex justify-start !font-semibold group-hover:opacity-70'
            }
          >
            Čítať viac
          </Button>
        </div>
      </div>
    </article>
  );
};

export default BlogCard;
