import {
  EditorJsBody,
  KnowledgeShareHeaderSection,
  StrapiSeo,
} from '@abclinic/ui';
import { graphql } from 'gatsby';
import { getImage } from 'gatsby-plugin-image';
import React, { useMemo } from 'react';
import { Layout } from '../../components/Layout/Layout';

export interface StrapiKnowledgeShareArticlePageProps {
  data: any;
}

export const query = graphql`
  query StrapiKnowledgeShareArticlePageQuery(
    $language: String!
    $slug: String!
  ) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    strapiKnowledgeShareArticle(slug: { eq: $slug }) {
      title
      alternativeTitle
      seo {
        ...SeoFragment
      }
      excerpt
      content {
        data {
          content
        }
      }
      image {
        localFile {
          childImageSharp {
            gatsbyImageData(quality: 90, placeholder: BLURRED)
          }
        }
      }
    }
  }
`;

export const StrapiKnowledgeShareArticlePage = (
  props: StrapiKnowledgeShareArticlePageProps
) => {
  const {
    data: { strapiKnowledgeShareArticle },
  } = props;

  const body = useMemo(() => {
    const da = strapiKnowledgeShareArticle?.content?.data?.content;
    if (!da) return [];
    return JSON.parse(da).blocks;
  }, [strapiKnowledgeShareArticle]);

  return (
    <Layout fullHeader>
      <StrapiSeo seo={strapiKnowledgeShareArticle.seo} />

      <KnowledgeShareHeaderSection
        title={strapiKnowledgeShareArticle.title}
        alternativeTitle={strapiKnowledgeShareArticle.alternativeTitle}
        excerpt={strapiKnowledgeShareArticle.excerpt}
        image={getImage(
          strapiKnowledgeShareArticle.image.localFile.childImageSharp
            .gatsbyImageData
        )}
      />
      <EditorJsBody blocks={body} />
    </Layout>
  );
};

export default StrapiKnowledgeShareArticlePage;
