import clsx from 'clsx';
import React from 'react';

export interface SectionHeaderProps {
  title: string;
  subtitle?: string;
  light?: boolean;
  className?: string;
  darkTitle?: boolean;
  variant?: 'gray' | 'darkblue' | 'light';
}

export const SectionHeader = (props: SectionHeaderProps) => {
  const {
    className,
    title,
    subtitle,
    darkTitle,
    light,
    variant = 'darkblue',
    ...restOfProps
  } = props;
  return (
    <header className={`flex flex-col space-y-4 ${className}`} {...restOfProps}>
      <h2
        className={clsx({
          'text-2xl md:text-4xl font-bold': true,
          'text-white': variant === 'light',
          'text-ultra': variant === 'darkblue',
          'text-titleText': variant === 'gray',
        })}
      >
        {title}
      </h2>

      {subtitle && (
        <p
          className={clsx({
            'text-base lg:text-lg font-light w-full lg:max-w-[64rem] text-text mt-2 lg:mt-5':
              true,
            'text-white': variant === 'light',
          })}
        >
          {subtitle}
        </p>
      )}
    </header>
  );
};
