import { HeroProps } from '@abclinic/ui';
import { HiOutlineCalendar } from '@react-icons/all-files/hi/HiOutlineCalendar';
import { StaticImage } from 'gatsby-plugin-image';
import { SharedHero } from '../../Shared/components/SharedHero/SharedHero';

export interface KnowledgeShareHeroSectionProps extends HeroProps {}
export const KnowledgeShareHeroSection = (
  props: KnowledgeShareHeroSectionProps
) => {
  return (
    <SharedHero
      title={props.title}
      titleBlue={props.titleBlue}
      subtitle={props.subtitle}
      button={{
        href: props.button?.href,
        label: props.button?.label,
        icon: <HiOutlineCalendar />,
      }}
      image={
        <div className="relative md:absolute mt-auto top-0 -right-10 sm:-right-20 md:right-0 bottom-0 flex w-full  md:w-[26rem] lg:w-[28rem] xl:w-[40rem] 2xl:w-[50rem] h-full items-center">
          <StaticImage
            className=""
            placeholder="tracedSVG"
            quality={35}
            imgClassName="w-full h-full"
            src={'./../assets/knowledgeshare_hero.png'}
            alt={`${props.title} ${props.titleBlue}`}
            title={`${props.title} ${props.titleBlue}`}
          />
        </div>
      }
    />
  );
};
