import React from 'react';

export interface QuickInfoItemProps {
  className?: string;
  items: {
    isLink?: boolean;
    title?: string;
    icon: React.ReactNode;
    sublabel: string;
    label: string;
    href: string;
  }[];
}

export const QuickInfoItem = (props: QuickInfoItemProps) => {
  const { items, className } = props;

  return (
    <article className={`py-10  space-y-8 flex flex-col ${className}`}>
      {items.map(
        (item) =>
          item?.label && (
            <a
              target="_blank"
              rel="noopener noreferrer"
              href={item?.href}
              className="flex space-x-4 items-center font-light text-xl max-w-[12rem] md:max-w-none "
            >
              <span className="icon text-xl md:text-3xl text-primary">
                {item?.icon}
              </span>
              <span className=" flex flex-col text-base md:text-base label text-secondary font-light opacity-90  hover:opacity-75">
                <span className="text-sm md:text-base text-slate-400">
                  {item.title}
                </span>
                {item?.label}
                {item.sublabel && (
                  <span className="block text-[0.7rem] md:text-sm label text-secondary text-opacity-90 font-light">
                    {item.sublabel}
                  </span>
                )}
              </span>
            </a>
          )
      )}
    </article>
  );
};
