import { EditorJsBody, StrapiSeo } from '@abclinic/ui';
import { graphql } from 'gatsby';
import { getImage } from 'gatsby-plugin-image';
import React, { useMemo } from 'react';
import { BlogHeaderSection } from '../../components/Blog/BlogHeaderSection';
import { Layout } from '../../components/Layout/Layout';

export interface ArticlePageProps {
  data: any;
}

export const query = graphql`
  query ArticlePageQuery($slug: String!) {
    strapiArticle(slug: { eq: $slug }) {
      title
      seo {
        ...SeoFragment
      }
      author {
        name
      }
      excerpt
      content {
        data {
          content
        }
      }
      image {
        localFile {
          childImageSharp {
            gatsbyImageData(quality: 90, placeholder: BLURRED)
          }
        }
      }
    }
  }
`;

export const ArticlePage = (props: ArticlePageProps) => {
  const { data } = props;

  const body = useMemo(() => {
    const da = data?.strapiArticle?.content?.data?.content;
    return da ? JSON.parse(da)?.blocks : [];
  }, [data]);

  return (
    <Layout fullHeader>
      <StrapiSeo seo={data.strapiArticle.seo} />
      <BlogHeaderSection
        excerpt={data.strapiArticle.excerpt}
        image={getImage(
          data.strapiArticle.image.localFile.childImageSharp.gatsbyImageData
        )}
        title={data.strapiArticle.title}
        author={data.strapiArticle.author?.name}
        userCanShareItem={true}
      />
      <EditorJsBody blocks={body} />
    </Layout>
  );
};

export default ArticlePage;
