import { Button, Container } from '@abclinic/ui';
import { Link } from 'gatsby';
import React from 'react';

export interface ArticleBannerSectionProps {
  className?: string;
  title: string;
  content: string;
  imageSrc?: string;
  button: {
    href: string;
    label: string;
  };
  image: React.ReactNode;
}

export const ArticleBannerSection = (props: ArticleBannerSectionProps) => {
  const { className = '', title, content, button, imageSrc, image } = props;
  return (
    <section
      className={`relative overflow-hidden max-w-full ${className}`}
      {...props}
    >
      <div
        className={`bg-ultraUltra  w-full md:w-11/12 lg:w-11/12   md:rounded-r-2xl h-full  left-0 bottom-0 z-0 relative`}
      >
        <Container className="z-12  py-12 lg:py-32 xl:py-40 max-w-7xl">
          {/* CONTENT */}
          <div className="content flex flex-col w-full   md:flex-row gap-x-9 justify-between  items-center sm:items-start md:items-center">
            {/* TEXT */}
            <article className="content-text pl-2 md:pl-0 md:pr-1 w-full md:w-3/5 lg:w-1/2 flex flex-col justify-center order-2 md:order-1 mt-8 md:mt-0">
              <h2 className="font-bold text-2xl md:text-3xl lg:text-4xl text-background">
                {title}
              </h2>
              <p className="mt-6 md:mt-8 text-base md:text-lg text-darkBackgroundText">
                {content}
              </p>
              <div className="flex">
                <Link to={button.href} className="mt-10 md:mt-16 inline-flex">
                  <Button variant="secondary" icon={true}>
                    {button.label}
                  </Button>
                </Link>
              </div>
            </article>

            {/* IMAGE  */}
            <div className="right-side flex flex-1 items-center justify-end  order-1 md:order-2  ">
              <div className="relative md:absolute w-auto h-96 md:h-[20rem] lg:h-[30rem] xl:h-[32rem] md:w-[20rem] lg:w-[30rem] xl:w-[34rem] flex items-center justify-center overflow-hidden rounded-2xl  lg:top-30  md:-right-12 lg:-right-12">
                {image}
              </div>
            </div>
          </div>
        </Container>
      </div>
    </section>
  );
};
