import { FaqItemsSection, SectionHeader } from '@abclinic/ui';
import React from 'react';
import { useContactPage } from '../../ContactPageContext/ContactPageContextProvider';

export interface FaqsSectionProps {
  className?: string;
}

export const FaqsSection = (props: FaqsSectionProps) => {
  const { className = '' } = props;
  const { faqSection } = useContactPage();

  return (
    <section className={`${className}`}>
      <SectionHeader
        variant="gray"
        title={faqSection.title}
        subtitle={faqSection.subtitle}
      />
      <FaqItemsSection
        faqItems={faqSection.items.map((item) => ({
          question: item.question,
          answer: item.answer,
          button: {
            href: item.link?.href,
            label: item.link?.label,
          },
        }))}
      />
    </section>
  );
};
