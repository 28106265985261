import { Button, Container, HeroProps, HeroTitle } from '@abclinic/ui';
import { HiAtSymbol } from '@react-icons/all-files/hi/HiAtSymbol';
import { HiOutlineLocationMarker } from '@react-icons/all-files/hi/HiOutlineLocationMarker';
import { HiOutlinePhone } from '@react-icons/all-files/hi/HiOutlinePhone';
import { StaticImage } from 'gatsby-plugin-image';
import { Link, useTranslation } from 'gatsby-plugin-react-i18next';
import MapContainer from '../../Shared/components/CustomGoogleMap/MapContainer';
import { QuickInfoItem } from '../../Shared/components/QuickInfos/QuickInfoItem';
import { useContactInfoItems } from '../../Shared/hooks/useContactInfoItems';
import { ContactHeroOpeningHoursCard } from './components/ContactHeroOpeningHoursCard';

export interface ContactHeroProps extends HeroProps {
  className?: string;
}

export const ContactHero = (props: ContactHeroProps) => {
  const {
    className = '',
    title,
    titleBlue,
    subtitle,
    button,
    image,
    rightBgColor,
  } = props;

  const { t, i18n } = useTranslation(['common']);
  const contactInfo = useContactInfoItems();

  return (
    <section
      className={`pt-36 md:pt-40 lg:pt-0 lg:pb-0 pb-64   lg:h-screen    min-h-[48rem] flex items-center relative overflow-hidden md:bg-background1 ${className}`}
    >
      <Container>
        <div className="flex flex-col lg:flex-row  h-full lg:gap-x-6 ">
          <div className="w-full md:w-1/2">
            <HeroTitle
              title={title}
              titleBlue={titleBlue}
              subtitle={subtitle}
            />
            <div className="flex w-full gap-y-4 lg:gap-y-8 flex-col mt-4 lg:mt-14 mb-8 lg:mb-0">
              <QuickInfoItem
                items={[
                  {
                    title: t('address'),
                    icon: <HiOutlineLocationMarker />,
                    ...contactInfo.address,
                  },
                  {
                    title: t('phone'),
                    icon: <HiOutlinePhone />,
                    ...contactInfo.phone,
                    ...contactInfo.sublabel,
                  },

                  {
                    title: t('email'),
                    icon: <HiAtSymbol />,
                    ...contactInfo.email,
                  },
                ]}
              />
            </div>

            {button && (
              <Link to={button.href} className="mt-14 block">
                <Button variant="primary" icon={button.icon}>
                  {button.label}
                </Button>
              </Link>
            )}
          </div>

          <div className="relative lg:absolute lg:top-0  lg:bottom-0 lg:right-0 z-0 w-full h-auto lg:w-[30rem] xl:w-[40rem] 2xl:w-[40rem]  lg:h-[90vh]  ">
            <div className="bg-mapBackgournd text-transparent h-[56vh] lg:h-[90vh] md:min-h-[48rem] w-full relative overflow-hidden rounded-bl-3xl rounded-3xl lg:rounded-t-none lg:rounded-br-none lg:rounded-bl-3xl shadow-lg">
              <MapContainer
                className={'h-[56vh] lg:h-[90vh] md:min-h-[48rem]'}
              />
            </div>
            {/* Card */}
            <ContactHeroOpeningHoursCard />

            <div className="cta relative lg:absolute bottom-20 w-5/6 lg:w-auto mx-auto lg:bottom-10 lg:left-10 flex justify-center z-30"></div>
          </div>
        </div>
      </Container>
    </section>
  );
};
