import { GatsbyImage, IGatsbyImageData } from 'gatsby-plugin-image';
import React from 'react';
import { BlogContainer } from '../../container/BlogContainer';

export interface KnowledgeShareHeaderSectionProps {
  image: IGatsbyImageData;
  title: string;
  alternativeTitle: string;
  author: string;
  excerpt: string;
}

export const KnowledgeShareHeaderSection = (
  props: KnowledgeShareHeaderSectionProps
) => {
  const { title, excerpt, image, alternativeTitle } = props;
  return (
    <header className=" mt-6 md:mt-12 mb-10">
      <BlogContainer className="space-y-12">
        {/* Image */}
        <div className="w-full h-56 sm:h-64 md:h-[35rem] rounded-3xl overflow-hidden">
          <GatsbyImage
            alt={''}
            imgClassName="w-full h-full rounded-3xl overflow-hidden"
            className="w-full h-full overflow-hidden rounded-3xl"
            image={image}
          />
        </div>
        {/* Title */}
        <div className="top  space-y-4 md:text-xl font-light text-textTerciary ">
          <div className="title flex md:justify-between md:items-center flex-col md:flex-row space-y-4 md:space-y-0">
            <h1 className="inline font-semibold  text-4xl lg:text-5xl text-titleText">
              <span className="inline">{title}</span>{' '}
              {alternativeTitle && (
                <span className="text-primary inline">{alternativeTitle}</span>
              )}
            </h1>
            {/* <ShareBlogActions /> */}
          </div>
          <div className="excerpt font-extralight text-lg opacity-75">
            {excerpt}
          </div>
        </div>
      </BlogContainer>
    </header>
  );
};
