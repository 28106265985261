import { Container } from '@abclinic/ui';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import React from 'react';
import { useNotices } from '../../hooks/useNotices';
import { Notice } from './Notice';

export interface NoticesProps {
  className?: string;
}

export const Notices = (props: NoticesProps) => {
  const notices = useNotices();
  const { t } = useTranslation();
  return (
    notices.length >= 1 && (
      <section>
        <Container>
          <div className="px-6 md:px-12 lg:px-24 py-8 lg:py-20  shadow-lg rounded-[32px] bg-white">
            <h2 className="text-ultra text-xl md:text-3xl font-bold mb-3 md:mb-8">
              {t('importantNotices')}
            </h2>

            <div className="flex flex-col space-y-8">
              {notices.map((notice) => (
                <Notice
                  title={notice.title}
                  slug={notice.slug}
                  excerpt={notice.excerpt}
                />
              ))}
            </div>
          </div>
        </Container>
      </section>
    )
  );
};
