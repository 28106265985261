import React from 'react';
import { StrapiLink } from '../../StrapiLink/StrapiLink';
import { FaqItem, FaqItemProps } from './FaqItem';

export interface FaqItemsProps {
  faqItems: FaqItemProps[];
  className?: string;
}

export const FaqItemsSection = (props: FaqItemsProps) => {
  const { faqItems, className } = props;
  return (
    <section
      {...props}
      className={`py-10 flex gap-y-10 flex-wrap w-full justify-center flex-col ${className}`}
    >
      {faqItems.map((faqItem, index) => {
        if (faqItem.button?.href) {
          return (
            <StrapiLink href={faqItem.button.href}>
              <FaqItem
                question={faqItem.question}
                answer={faqItem.answer}
                button={{
                  href: faqItem.button?.href,
                  label: faqItem.button?.label,
                }}
                className="pt-6 pb-4 px-7 flex flex-col w-full"
              />
            </StrapiLink>
          );
        }
        return (
          <FaqItem
            question={faqItem.question}
            answer={faqItem.answer}
            className="pt-6 pb-4 px-7 flex flex-col w-full"
          />
        );
      })}

      {/* <Link to="/faq">
        <Button transparent icon className="!text-terciary">
          Všetky často kladené otázky
        </Button>
      </Link> */}
    </section>
  );
};
