import React from 'react';
import { useEquipmentCardsData } from '../hooks/useEquipmentCardsData';
import { TechnologieItem } from './TechnologieItem';

export const TechnologieItems = () => {
  const equipments = useEquipmentCardsData();

  return (
    <div className="flex flex-wrap flex-row gap-x-4 gap-y-6 md:gap-12 lg:gap-6 xl:gap-10 justify-center xl:justify-end">
      {equipments.map((eq, index) => (
        <TechnologieItem
          title={{
            bold: eq.title.bold,
            normal: eq.title.normal,
          }}
          subtitle={eq.subtitle}
          image={eq.image}
          className={eq.className}
          key={index}
        />
      ))}
    </div>
  );
};
