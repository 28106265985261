import { Container, SectionHeader } from '@abclinic/ui';
import React from 'react';
import { QuickNavigationCards } from './components/QuickNavigationCards';
import { quickNavigationTempData } from './components/tempData';

export const HomeQuickNavigationSection = () => {
  return (
    <section className="py-4 relative max-w-full overflow-visible">
      <div className="bg-ultraUltra w-5 lg:w-6 h-full rounded-r-2xl  z-0 top-0 -left-2  bottom-0 absolute md:block hidden" />
      <Container className="z-10 relative space-y-12 lg:space-y-24">
        <SectionHeader
          title={'Rýchla navigácia'}
          subtitle={
            'Otvorte si rovno to, kvôli čomu ste se prišli, bez zbytočného blúdenia v mori informácií, ktoré vás zatiaľ nezaujímajú.'
          }
          className="max-w-xl"
        />
        <QuickNavigationCards navigation={quickNavigationTempData} />
      </Container>
    </section>
  );
};
