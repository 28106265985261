import { SectionHeader } from '@abclinic/ui';
import React from 'react';
import { ContactUsForm } from '../../../Shared/components/ContactUsForm/ContactUsForm';
import { useContactPage } from '../../ContactPageContext/ContactPageContextProvider';

export interface ContactSectionProps {
  className?: string;
}

export const ContactSection = (props: ContactSectionProps) => {
  const { className = '' } = props;
  const { contactFormHeader } = useContactPage();
  return (
    <section {...props} className={`${className}`}>
      <SectionHeader variant="gray" {...contactFormHeader} />
      <ContactUsForm formButtonPosition="left" />
    </section>
  );
};
