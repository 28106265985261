import React from "react";
import { IImageBlock } from "../model/editorjs";

export type ImageBlockProps = IImageBlock

export const ImageBlock = (props: ImageBlockProps) => {
  const { data: { caption, file, withBorder, withBackground, stretched } } = props;

  return (
    <img src={`${process.env.GATSBY_API_URL}${file.url}`} alt="" className="rounded-3xl !my-16" />
  );
};
