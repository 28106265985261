import { Container } from '@abclinic/ui';
import { BoxProps } from '@chakra-ui/react';
import React from 'react';
import { ContactSection } from './components/ContactSection';
import { FaqsSection } from './components/FaqsSection';

export interface FaqsAndContactSectionProps extends BoxProps {
  className?: string;
}

export const FaqsAndContactSection = (props: FaqsAndContactSectionProps) => {
  const { className = '' } = props;
  return (
    <section className="bg-white pt-20 lg:py-36">
      <Container>
        <div className="flex flex-col lg:flex-row w-full md:gap-x-16 xl:gap-x-32 gap-y-20">
          <FaqsSection className="flex w-full lg:w-1/2 flex-col" />
          <ContactSection className="flex w-full lg:w-1/2 flex-col" />
        </div>
      </Container>
    </section>
  );
};
