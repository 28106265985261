import { Button } from '@abclinic/ui';
import { FaLocationArrow } from '@react-icons/all-files/fa/FaLocationArrow';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import React from 'react';
import { useContactPage } from '../../ContactPageContext/ContactPageContextProvider';
import { OpeningHoursItem } from './OpeningHoursItem';

export interface ContactHeroOpeningHoursCardProps {}

export const ContactHeroOpeningHoursCard = (
  props: ContactHeroOpeningHoursCardProps
) => {
  const { t } = useTranslation();
  const { openingHours } = useContactPage();


  return (
    <div className="absolute p-8 bg-white rounded-2xl lg:bottom-20 lg:-left-20 -bottom-52 right-0 left-0 mx-auto lg:mx-0 w-10/12 md:w-[30rem] lg:w-[20rem] lg:w-unset shadow-xl ">
      <header>
        <h2 className="font-semibold text-lg text-ultraUltra">
          {t('openingHours')}
        </h2>
      </header>
      <div className="hours space-y-2 mt-3">
        {openingHours?.map((item) => (
          <OpeningHoursItem key={item.day} {...item} />
        ))}
      </div>
      <a
        href="https://goo.gl/maps/Qak6kJnfSFzCdHNR7"
        target="_blank"
        rel="noopener noreferrer"
        className="w-full mt-8 flex"
      >
        <Button
          className="w-full !rounded-full"
          variant="primary"
          icon={<FaLocationArrow />}
        >
          {t('navigate')}
        </Button>
      </a>
    </div>
  );
};
