import React from 'react';
import { IHeaderBlock } from '../model/editorjs';

export type HeaderBlockProps = IHeaderBlock;

export const HeaderBlock = (props: HeaderBlockProps) => {
  const {
    data: { level, text },
  } = props;

  switch (level) {
    case 1:
      return (
        <h1 className="text-4xl md:text-5xl !mt-12 text-ultraUltra font-semibold">
          {text}
        </h1>
      );
    case 2:
      return (
        <h2 className="text-2xl md:text-3xl !mt-8 text-ultraUltra font-semibold">
          {text}
        </h2>
      );
    case 3:
      return (
        <h3 className="text-lg md:text-xl !mt-6  text-ultraUltra font-semibold">
          {text}
        </h3>
      );
    case 4:
      return (
        <h4 className="text-md md:text-lg !mt-4  text-ultraUltra font-semibold">
          {text}
        </h4>
      );
    case 5:
      return (
        <h5 className="md:text-lg text-terciary  text-ultraUltra font-semibold">
          {text}
        </h5>
      );
    case 6:
      return <h6 className="md:text-base">{text}</h6>;
  }
};
