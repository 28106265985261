import { BlogSection, PageSectionSpacer, StrapiSeo } from '@abclinic/ui';
import { graphql } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { KnowledgeShareFeaturedArticleSection } from '../../components/KnowledgeShare/FeaturedArticleSection/KnowledgeShareFeaturedArticleSection';
import { KnowledgeShareHeroSection } from '../../components/KnowledgeShare/Hero/KnowledgeShareHeroSection';
import { KnowledgeShareCtaBanner } from '../../components/KnowledgeShare/KnowledgeShareCtaBanner/KnowledgeShareCtaBanner';
import { KnowledgeShareContextProvider } from '../../components/KnowledgeShare/KnowledgeSharePageContext/KnowledgeShareContextProvider';
import { KnowledgeSharePageSection } from '../../components/KnowledgeShare/KnowledgeShareSection/KnowledgeSharePageSection';
import { Layout } from '../../components/Layout/Layout';
import { ArticleBannerSection } from '../../components/Shared/components/ArticleBannerSection/ArticleBannerSection';
import { ContactFormWithContactInfoSection } from '../../components/Shared/components/ContactFormWithContactInfoSection/ContactFormWithContactInfoSection';
import { generateArticleRoute } from '../../lib/routes';

export const KnowledgeSharePage = (props: any) => {
  const {
    data: {
      strapiKnowledgeSharePage,
      strapiFeaturedArticlesSection,
      strapiArticleBannerSection,
    },
  } = props;

  return (
    <Layout>
      <KnowledgeShareContextProvider data={strapiKnowledgeSharePage}>
        <StrapiSeo seo={strapiKnowledgeSharePage?.seo} />
        <PageSectionSpacer>
          <KnowledgeShareHeroSection {...strapiKnowledgeSharePage?.hero} />
          <KnowledgeSharePageSection />
          <KnowledgeShareFeaturedArticleSection
            sectionHeader={{
              title: strapiKnowledgeSharePage?.regularArticles.title,
              subtitle: strapiKnowledgeSharePage?.regularArticles.subtitle,
            }}
          />
          <KnowledgeShareCtaBanner {...strapiKnowledgeSharePage?.ctaBanner} />
          <ArticleBannerSection
            title={strapiArticleBannerSection?.article?.article.title}
            content={strapiArticleBannerSection?.article.article.excerpt}
            button={{
              label: strapiArticleBannerSection?.buttonLabel,
              href: generateArticleRoute(
                strapiArticleBannerSection?.article?.article.slug
              ),
            }}
            image={
              <GatsbyImage
                className="w-full h-full"
                image={getImage(
                  strapiArticleBannerSection.article.article.image.localFile
                    .childImageSharp.gatsbyImageData
                )}
                imgClassName="rounded-xl overflow-hidden"
                alt={
                  strapiArticleBannerSection.article.article.image
                    .alternativeText
                }
              />
            }
            {...props}
          />
          <ContactFormWithContactInfoSection />
          <BlogSection
            header={strapiFeaturedArticlesSection.header}
            articles={strapiFeaturedArticlesSection?.featured?.articles.map(
              (article: any) => ({
                author: article.author?.name,
                title: article.title,
                perex: article.excerpt,
                route: generateArticleRoute(article.slug),
                slug: article.slug,
                image: (
                  <GatsbyImage
                    className="w-full h-full"
                    image={getImage(
                      article.image.localFile.childImageSharp.gatsbyImageData
                    )}
                    imgClassName="rounded-xl overflow-hidden"
                    alt={article.image.alternativeText}
                  />
                ),
              })
            )}
          />
        </PageSectionSpacer>
      </KnowledgeShareContextProvider>
    </Layout>
  );
};

export const query = graphql`
  fragment ArticleCardFragment on STRAPI_KNOWLEDGE_SHARE_ARTICLE {
    title
    slug
    image {
      localFile {
        childImageSharp {
          gatsbyImageData(
            quality: 40
            width: 500
            layout: FULL_WIDTH
            placeholder: BLURRED
          )
        }
      }
      alternativeText
    }
    excerpt
    alternativeTitle
  }

  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }

    strapiKnowledgeSharePage(locale: { eq: $language }) {
      seo {
        ...SeoFragment
      }
      hero {
        title
        titleBlue
        subtitle
        button {
          label
          href
        }
      }
      ctaBanner {
        description
        title
        titleBlue
        button {
          label
          href
        }
      }
      regularArticles {
        title
        subtitle
        knowledge_share_articles {
          ...ArticleCardFragment
        }
      }
      topArticles {
        title
        subtitle
        knowledge_share_articles {
          ...ArticleCardFragment
        }
      }
    }
    strapiFeaturedArticlesSection(locale: { eq: $language }) {
      ...FeaturedArticlesSectionFragment
    }
    strapiArticleBannerSection(locale: { eq: $language }) {
      ...ArticleBannerSectionFragment
    }
  }
`;

export default KnowledgeSharePage;
