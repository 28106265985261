import { Container, SectionHeader } from '@abclinic/ui';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { StaffMember, StaffMemberProps } from './components/StaffMember';
import { useStaffMembers } from './hooks/useStaffMembers';

export const AboutUsStaffMembers = () => {
  const staffMembers = useStaffMembers();
  const { t } = useTranslation();
  return (
    <section>
      <Container className="space-y-12 lg:space-y-24">
        <SectionHeader
          title={t('ourTeam')}
          className="text-center md:text-left"
        />
        <div className="grid grid-cols-1 md:grid-cols-2 gap-y-16 gap-x-10">
          {staffMembers.map((member: StaffMemberProps) => (
            <StaffMember
              name={member.name}
              position={member.position}
              description={member.description}
              image={
                <GatsbyImage
                  className="w-full h-full rounded-full overflow-hidden"
                  image={getImage(
                    member.image.localFile.childImageSharp.gatsbyImageData
                  )}
                  imgClassName="rounded-full overflow-hidden"
                  alt={`Člen očnej ambulancie ${member.name}`}
                />
              }
            />
          ))}
        </div>
      </Container>
    </section>
  );
};
