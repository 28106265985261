import { PageSectionSpacer, StrapiSeo } from '@abclinic/ui';
import { graphql } from 'gatsby';
import React from 'react';
import { EquipmentsHeroSection } from '../../components/Equipments/EquipmentsHero/EquipmentsHeroSection';
import { EquipmentsSection } from '../../components/Equipments/EquipmentsSection/EquipmentsSection';
import { Layout } from '../../components/Layout/Layout';
import { BookAnAppointmentSection } from '../../components/Shared/components/BookAnAppointmentSection/BookAnAppointmentSection';

export interface EquipmentPageProps {
  data: any;
}

export const EquipmentPage = (props: EquipmentPageProps) => {
  const { data } = props;

  return (
    <Layout>
      <StrapiSeo seo={data?.strapiEquipmentPage?.seo} />
      <PageSectionSpacer>
        <EquipmentsHeroSection {...data?.strapiEquipmentPage?.hero} />
        <EquipmentsSection />
        <BookAnAppointmentSection />
      </PageSectionSpacer>
    </Layout>
  );
};

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    strapiEquipmentPage {
      seo {
        ...SeoFragment
      }
      hero {
        title
        titleBlue
        subtitle
        button {
          label
          href
        }
      }
    }
  }
`;

export default EquipmentPage;
