import React from 'react';
import { ContactPage } from './model/contact-page.model';

export interface ContactPageContextProviderProps {
  children?: React.ReactNode | React.ReactNodeArray;
  data: ContactPage;
}

const ContactPageContext = React.createContext<ContactPage | null>(null);

export const useContactPage = () => {
  const context = React.useContext(ContactPageContext);
  if (context === null) {
    throw new Error('useContactPage must be used within a ContactPageProvider');
  }
  return context;
};

export const ContactPageContextProvider = (
  props: ContactPageContextProviderProps
) => {
  const { children, data } = props;
  return (
    <ContactPageContext.Provider value={data}>
      {children}
    </ContactPageContext.Provider>
  );
};
