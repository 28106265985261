import { Container, PageSectionSpacer, StrapiSeo } from '@abclinic/ui';
import { graphql } from 'gatsby';
import { StaticImage } from 'gatsby-plugin-image';
import { Layout } from '../../components/Layout/Layout';
import { ServicesList } from '../../components/Services/ServicesList/ServicesList';
import { SharedHero } from '../../components/Shared/components/SharedHero/SharedHero';

export interface ServicesPageProps {
  data: any;
}

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    strapiServicesPage {
      seo {
        ...SeoFragment
      }
      hero {
        subtitle
        title
        titleBlue
      }
    }

    allStrapiService {
      nodes {
        id
        name
        slug
        shortDescription
        image {
          localFile {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
      }
    }
  }
`;

export const ServicesPage = (props: ServicesPageProps) => {
  const {
    data: { allStrapiService, strapiServicesPage },
  } = props;
  return (
    <Layout>
      <StrapiSeo seo={strapiServicesPage?.seo} />
      <PageSectionSpacer>
        <SharedHero
          {...strapiServicesPage.hero}
          image={
            <div className="mb-10 relative md:absolute mt-auto -top-4 md:top-0 -right-10 sm:-right-20 md:right-0 bottom-0 flex  w-[18rem] md:w-[24rem] lg:w-[28rem]  2xl:w-[34rem] items-center">
              <StaticImage
                className="w-full"
                imgClassName="w-full h-full"
                placeholder="tracedSVG"
                quality={35}
                src={'../../components/Services/assets/services_hero.png'}
                alt={`${strapiServicesPage.hero.title} ${strapiServicesPage.hero.titleBlue}`}
                title={`${strapiServicesPage.hero.title} ${strapiServicesPage.hero.titleBlue}`}
              />
            </div>
          }
        />
        <Container>
          <ServicesList services={allStrapiService} />
        </Container>
      </PageSectionSpacer>
    </Layout>
  );
};

export default ServicesPage;
