import { Link } from 'gatsby-plugin-react-i18next';
import React from 'react';

export interface StrapiLinkProps {
  children: React.ReactNode;
  className?: string;
  href: string;
}

export const StrapiLink = (props: StrapiLinkProps) => {
  const { href, children } = props;
  if (href.indexOf('http') === -1) {
    return (
      <Link to={href} className="flex flex-1">
        <>{children}</>
      </Link>
    );
  }
  return (
    <a href={href} target="_blank" rel="noopener noreferrer">
      {children}
    </a>
  );
};
