import { StaticImage } from 'gatsby-plugin-image';
import { TechnologieItemProps } from '../components/TechnologieItem';

export const useEquipmentCardsData = (): TechnologieItemProps[] => {
  return [
    {
      className: '-mt-10',
      title: {
        bold: 'Foropter',
        normal: 'Reichert',
      },

      subtitle:
        'Tento prístroj nahrádza tradičné skúšacie okuliare s výmennými sklíčkami.',
      image: (
        <div className="-top-1 -right-1 w-32 sm:w-auto  absolute">
          <StaticImage
            placeholder="tracedSVG"
            src={'../assets/foropter.png'}
            alt={'Foropter'}
            quality={100}
          />
        </div>
      ),
    },
    {
      className: 'mt-10',

      title: {
        bold: 'Medmont',
        normal: 'M700',
      },

      subtitle: 'Zariadenie na vyšetrenie rozsahu a výpadkov zorného poľa oka.',
      image: (
        <div className="-top-1 sm:-top-4  sm:-right-20 w-52 sm:w-80 absolute">
          <StaticImage
            placeholder="tracedSVG"
            src={'../assets/medmont.png'}
            alt={'Medmont'}
            quality={90}
          />
        </div>
      ),
    },

    {
      className: '-mt-20',
      title: {
        bold: 'Topcon',
        normal: 'CT-1P',
      },

      subtitle: 'Prístroj na bezkontaktné meranie vnútroočného tlaku.',
      image: (
        <div className="top-0 -right-8 sm:-right-12 w-44 sm:w-[16.8rem] absolute">
          <StaticImage
            placeholder="tracedSVG"
            src={'../assets/topcon-ct.png'}
            quality={80}
            alt={'Abscan'}
          />
        </div>
      ),
    },
    {
      title: {
        bold: 'Topcon',
        normal: 'RM 8900',
      },

      subtitle:
        'Tento prístroj nám pomáha určiť optimálnu kombináciu dioptrií.',
      image: (
        <div className="top-3 -right-10 sm:top-2 sm:-right-16 w-[11.2rem] sm:w-[17.4rem]  absolute -scale-x-100">
          <StaticImage
            placeholder="tracedSVG"
            src={'../assets/topcon-rm.png'}
            alt={'Keratometer'}
            quality={60}
          />
        </div>
      ),
    },
  ];
};
