import React from 'react';
import { Button } from '../../button/Button';
import { Card } from '../../card/Card';
import { FaqItem as IFaqItem } from '../../model/faqItem.model';
import { StrapiLink } from '../../StrapiLink/StrapiLink';

export interface FaqItemProps extends IFaqItem {
  className?: string;
}

export const FaqItem = (props: FaqItemProps) => {
  const { className = '', question, answer, button } = props;

  return (
    <Card
      {...props}
      className={`${className} hover:drop-shadow-2xl w-full flex flex-1 !p-8`}
    >
      <h3 className="font-bold text-lg leading-5">{question}</h3>
      <p className="mt-2 text-sm">{answer}</p>

      {button && (
        <div className="mt-5">
          <StrapiLink href={button.href} className="mt-4 flex">
            <Button variant="transparent" className="font-medium ">
              {button.label}
            </Button>
          </StrapiLink>
        </div>
      )}
    </Card>
  );
};
