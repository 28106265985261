import React from 'react';
import { Control, useController } from 'react-hook-form';

export interface TextAreaProps {
  label: string;
  name: string;
  className?: string;
  placeholder?: string;
  control: Control;
  rules?: any;
}

export const TextArea = (props: TextAreaProps) => {
  const {
    className = '',
    control,
    label,
    name,
    rules,
    placeholder,
    ...restOfProps
  } = props;
  const {
    field: { onChange, onBlur, value, ref },
    fieldState: { invalid, isTouched, isDirty, error },
    formState: { touchedFields, dirtyFields },
  } = useController({
    name,
    control,
    rules,
    defaultValue: '',
  });

  return (
    <div className={`flex flex-col flex-1 ${className}`}>
      <label htmlFor={name} className="text-dark text-sm leading-4 font-light">
        {label}
      </label>
      <textarea
        onChange={onChange} // send value to hook form
        onBlur={onBlur} // notify when input is touched/blur
        value={value} // input value
        name={name} // send down the input name
        ref={ref}
        id={name}
        rows={10}
        placeholder={placeholder}
        className="mt-2.5 focus:ring-2 flex w-full border-none  sm:text-sm  rounded-lg  bg-gray-100 text-text placeholder:text-black placeholder:text-opacity-20 lg:text-md py-4 px-6 font-light"
        defaultValue={''}
        {...restOfProps}
      />
      {error && (
        <div className="text-xs text-red-300 mt-2">{error['message']}</div>
      )}
    </div>
  );
};
