import React from 'react';
import { IParagraphBlock } from '../model/editorjs';

export type ParagraphBlockProps = IParagraphBlock;

export const ParagraphBlock = (props: ParagraphBlockProps) => {
  const {
    data: { text },
  } = props;
  return (
    <p
      className="text-base md:text-unset"
      dangerouslySetInnerHTML={{ __html: text }}
    ></p>
  );
};
