import React from 'react';
import { Notices } from '../../Shared/components/Notices/Notices';

export interface HomeNoticesProps {}

export const HomeNotices = (props: HomeNoticesProps) => {
  const {} = props;
  return (
    <section>
      <Notices />
    </section>
  );
};
