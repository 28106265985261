import { GatsbyImage, getImage, IGatsbyImageData } from 'gatsby-plugin-image';
import React from 'react';
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';
import { Container } from '../../container/Container';
import {
  SectionHeader,
  SectionHeaderProps,
} from '../../section/sectionHeader/SectionHeader';

export interface GalleryItem {
  image: IGatsbyImageData;
  url: string;
}

export interface GallerySectionProps {
  header?: SectionHeaderProps;
  images: GalleryItem[];
}

export const GallerySection = (props: GallerySectionProps) => {
  const { header, images } = props;

  const [isLightBoxOpen, setIsLightBoxOpen] = React.useState(false);
  const [lightBoxIndex, setLightBoxIndex] = React.useState(0);

  return (
    <Container className="gallery page-section space-y-12 lg:space-y-14">
      {header && <SectionHeader {...header} />}
      <div className="gallery grid grid-cols-2  sm:grid-cols-3 lg:grid-cols-4 gap-5 md:gap-12">
        {images.map((image, index) => (
          <div
            key={index}
            className="gallery__item aspect-4/3 rounded-2xl overflow-hidden cursor-pointer shadow-lg hover:shadow-xl relative group aspect-square"
            onClick={() => {
              setIsLightBoxOpen(true);
              setLightBoxIndex(index);
            }}
          >
            <div className="overlay hidden md:flex absolute top-0 bottom-0 left-0 right-0  opacity-30 z-10 group-hover:opacity-0"></div>
            <GatsbyImage
              className="h-full rounded-2xl overflow-hidden"
              imgClassName="rounded-2xl overflow-hidden h-full"
              image={image.image}
              alt={`Galéria fotka`}
            />
          </div>
        ))}
      </div>
      {isLightBoxOpen && (
        <Lightbox
          mainSrc={images[lightBoxIndex].url}
          closeLabel="close"
          prevLabel="prev"
          nextSrc={images[(lightBoxIndex + 1) % images.length].url}
          prevSrc={
            images[(lightBoxIndex + images.length - 1) % images.length].url
          }
          onCloseRequest={() => setIsLightBoxOpen(false)}
          clickOutsideToClose={true}
          onMovePrevRequest={() =>
            setLightBoxIndex(
              (lightBoxIndex + images.length - 1) % images.length
            )
          }
          onMoveNextRequest={() =>
            setLightBoxIndex((lightBoxIndex + 1) % images.length)
          }
        />
      )}
    </Container>
  );
};
