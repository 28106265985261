import { Container, KnowledgeShareSection, SectionHeader } from '@abclinic/ui';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import React from 'react';
import { generateKnowledgeBaseArticleRoute } from '../../../lib/routes';
import { useKnowledgeSharePage } from '../KnowledgeSharePageContext/KnowledgeShareContextProvider';
import { useKnowledgeShareItems } from './hooks/useKnowledgeShareItems';

export interface KnowledgeSharePageSectionProps {
  className?: string;
}

export const KnowledgeSharePageSection = (
  props: KnowledgeSharePageSectionProps
) => {
  const { className = '' } = props;
  const items = useKnowledgeShareItems();
  const { topArticles } = useKnowledgeSharePage();
  return (
    <Container>
      <SectionHeader
        title={topArticles.title}
        subtitle={topArticles.subtitle}
      />
      <KnowledgeShareSection
        knowledgeItems={topArticles.knowledge_share_articles.map((article) => ({
          title: article.title,
          slug: article.slug,
          subtitle: article.alternativeTitle,
          link: generateKnowledgeBaseArticleRoute(article.slug),
          perex: article.excerpt,
          image: (
            <GatsbyImage
              className="max-h-64 w-full md:h-full"
              imgClassName="rounded-t-xl overflow-hidden"
              image={getImage(
                article.image.localFile.childImageSharp.gatsbyImageData
              )}
              alt={article.title}
            />
          ),
        }))}
        {...props}
      />
    </Container>
  );
};
