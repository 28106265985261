import React from 'react';
import { Button } from '../../button/Button';
import { Card } from '../../card/Card';
import { Article } from '../../model/article.model';

export interface FeaturedArticleProps extends Article {
  className?: string;
}

export const FeaturedArticle = (props: FeaturedArticleProps) => {
  const { className = '', image, title, subtitle, perex } = props;
  return (
    <Card {...props} className={`group ${className} hover:shadow-lg`}>
      <article className="flex flex-col xl:flex-row lg:justify-between justify-center xl:items-center p-6">
        {/* Left side */}
        <div className="relative   2xl:w-[11rem] ">
          <div className="h-full mb-4 xl:mb-0 group-hover:drop-shadow-xl">
            {image}
          </div>
        </div>

        {/*  Right side */}
        <div className="content flex flex-col flex-auto h-full">
          <header className="flex items-center gap-4">
            <h3 className="text-terciary font-semibold text-xl leading-7">
              {title}
            </h3>
            <p className="text-primary font-semibold text-lg leading-5">
              {subtitle}
            </p>
          </header>

          <p className="pt-3 md:pt-5 text-text font-light leading-6 text-base mb-4 md:mb-6 inline-block">
            {perex}
          </p>

          <Button variant="transparent" icon className={'flex !justify-start'}>
            Čítať viac
          </Button>
        </div>
      </article>
    </Card>
  );
};
