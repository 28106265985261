import clsx from 'clsx';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import React from 'react';
import { EquipmentItem } from '../../model/equipments.model';

export interface EquipmentProps extends EquipmentItem {
  className?: string;
  isEven?: boolean;
}

const changeColorOfLastWord = (sentence: string) => {
  const splitString = sentence.split(' ');

  if (splitString.length > 1) {
    const lastWord = splitString[splitString.length - 1];

    const lastIndexOfSpace = sentence.lastIndexOf(' ');
    const sentenceWithoutLastWord = sentence.substring(0, lastIndexOfSpace);

    return (
      <>
        {sentenceWithoutLastWord}{' '}
        <span className="text-primary">{lastWord}</span>
      </>
    );
  } else {
    return sentence;
  }
};
export const Equipment = (props: EquipmentProps) => {
  const { name, shortDescription, image, className, isEven } = props;

  const imageData = getImage(image);
  return (
    <article
      className={`relative w-full flex  flex-col odd:md:flex-row even:md:flex-row-reverse justify-between items-center gap-y-2 md:gap-y-0 gap-x-12 overflow-visible  ${className} `}
    >
      <div className=" image md:w-1/2 w-full flex items-start relative h-[30rem] overflow-visible">
        <div
          className={`absolute top-10 ${
            isEven ? 'left-0' : 'right-0'
          } bg-ultraUltra w-56 sm:w-[300px] h-96 rounded-2xl`}
        ></div>

        <div
          className={`absolute top-24 sm:top-18 md:top-8 ${
            isEven
              ? 'sm:-left-20 md:-left-0 lg:left-6 xl:-left-10 2xl:-left-28'
              : 'sm:-right-20 md:-right-0 lg:-right-6 xl:-right-10 2xl:-right-28'
          } flex justify-center h-full min-h-full min-w-[20rem] w-full `}
        >
          {imageData && (
            <GatsbyImage
              alt={name}
              objectFit="contain"
              style={{
                objectFit: 'contain',
              }}
              className="md:h-full h-[24rem] w-full sm:w-[24rem] md:w-[28rem] lg:w-[28rem] object-contain"
              image={imageData}
            />
          )}
          {/* <div className="absolute  bg-yellow-500 w-56 sm:w-[300px] h-[30rem] rounded-2xl"></div> */}
        </div>
      </div>

      <header className=" md:w-1/2 w-full">
        <h2 className="font-bold text-ultra text-xl md:text-4xl  mb-4 last-child:text-primary max-w-lg">
          {changeColorOfLastWord(name)}
        </h2>

        <p className="font-light text-lg text-text max-w-2xl">
          {shortDescription}
        </p>
      </header>
    </article>
  );
};
