import { GallerySection, PageSectionSpacer, StrapiSeo } from '@abclinic/ui';
import { graphql } from 'gatsby';
import { getImage } from 'gatsby-plugin-image';
import { AboutUsStaffMembers } from '../components/AboutUs/AboutUsStaffMembers/AboutUsStaffMembers';
import { Layout } from '../components/Layout/Layout';
import { SharedHero } from '../components/Shared/components/SharedHero/SharedHero';

export interface AboutUsPageProps {
  data: any;
}

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    strapiAboutUsPage {
      seo {
        ...SeoFragment
      }
      clinic {
        ...GalleryFragment
      }
      store {
        ...GalleryFragment
      }
      hero {
        title
        subtitle
        titleBlue
      }
    }
  }
`;

export const AboutUsPage = (props: AboutUsPageProps) => {
  const {
    data: { strapiAboutUsPage },
  } = props;
  return (
    <Layout>
      <StrapiSeo seo={strapiAboutUsPage.seo} />
      <PageSectionSpacer>
        <SharedHero {...strapiAboutUsPage.hero} />
        <AboutUsStaffMembers />
        <GallerySection
          header={{
            ...strapiAboutUsPage.clinic.header,
          }}
          images={strapiAboutUsPage.clinic.images.map((strapiImage: any) => ({
            image: getImage(
              strapiImage.localFile.childImageSharp.gatsbyImageData
            ),
            url: `${process.env.GATSBY_API_URL}${strapiImage?.formats.large.url}`,
          }))}
        />
        <GallerySection
          header={{
            ...strapiAboutUsPage.store.header,
          }}
          images={strapiAboutUsPage.store.images.map((strapiImage: any) => {
            console.log(strapiImage);

            return {
              image: getImage(
                strapiImage.localFile.childImageSharp.gatsbyImageData
              ),
              url: `${process.env.GATSBY_API_URL}${strapiImage?.formats.large.url}`,
            };
          })}
        />
      </PageSectionSpacer>
    </Layout>
  );
};

export default AboutUsPage;
