export namespace StrapiSeoNamespace {

  export interface LocalFile {
    url: string;
  }

  export interface MetaImage {
    localFile: LocalFile;
  }

  export interface LocalFile2 {
    url: string;
  }

  export interface Image {
    alternativeText: string;
    localFile: LocalFile2;
  }

  export interface MetaSocial {
    title: string;
    socialNetwork: string;
    description: string;
    image: Image;
  }

  export interface StructuredData {
    name: string;
  }

  export interface Root {
    canonicalURL: string;
    metaImage: MetaImage;
    metaSocial: MetaSocial[];
    metaTitle: string;
    metaDescription: string;
    structuredData: StructuredData;
  }
}

