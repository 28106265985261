import { generateServiceRoute } from '../../../lib/routes';
import { Link } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import { KnowledgeShareItem } from '../../../../../../libs/ui/src/lib/sections/KnowledgeShareSection/KnowledgeShareItem';
import { Service } from '../model/service.model';
import { getFirstNWords } from '../../../lib/utils/getFirstNWords';

export interface ServicesListProps {
  services: Service[];
}

export const ServicesList = (props: ServicesListProps) => {
  const { services } = props;
  return (
    <div className="gap-12 sm:gap-8 md:gap-10 xl:gap-16 grid xl:grid-cols-3 sm:grid-cols-2  grid-cols-1  w-full min-w-[16rem]">
      {services?.nodes?.map((service: Service, index: number) => (
        <Link to={generateServiceRoute(service.slug)}>
          <KnowledgeShareItem
            className="!group-hover:shadow-xl"
            title={service.name}
            perex={getFirstNWords(service.shortDescription, 18)}
            subtitle={''}
            image={
              <div className=" w-full">
                <GatsbyImage
                  className="h-52 md:h-64 w-full  "
                  imgClassName="rounded-t-xl overflow-hidden"
                  image={
                    service.image.localFile.childImageSharp.gatsbyImageData
                  }
                  alt={service.name}
                />
              </div>
            }
          />
        </Link>
      ))}
    </div>
  );
};
