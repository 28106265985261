import { StrapiSeo } from "@abclinic/ui"
import { graphql } from "gatsby"

import { ArticleListController } from "../../components/Blog/ArticleListController"
import { Layout } from "../../components/Layout/Layout"
import { SharedHero } from "../../components/Shared/components/SharedHero/SharedHero"

export interface BlogHubPageProps {
  data: any;
}

export const query = graphql`
  query BlogHubPageQuery($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    strapiBlogListPage {
      seo {
        ...SeoFragment
      }
      hero {
        title
        titleBlue
        subtitle
      }
    }
    allStrapiArticle {
      pageInfo {
        totalCount
        hasNextPage
        currentPage
      }
      nodes {
        ...RegularArticleCardFragment
      }
    }
  }
`;

export const BlogHubPage = (props: BlogHubPageProps) => {
  const {
    data: { allStrapiArticle, strapiBlogListPage },
  } = props;

  return (
    <Layout>
      <StrapiSeo seo={strapiBlogListPage?.seo} />
      <div className="lg:space-y-36 space-y-16">
        <SharedHero
          title={strapiBlogListPage?.hero.title}
          titleBlue={strapiBlogListPage?.hero.titleBlue}
          subtitle={strapiBlogListPage?.hero.subtitle}
        />
        <ArticleListController articles={allStrapiArticle} />
      </div>
    </Layout>
  );
};

export default BlogHubPage;
