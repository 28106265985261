import React from 'react';

export interface PageSectionSpacerProps {
  children?: React.ReactNode[];
}

export const PageSectionSpacer = (props: PageSectionSpacerProps) => {
  const { children } = props;
  return (
    <div className="space-y-16 sm:space-y-20 lg:space-y-36">{children}</div>
  );
};
