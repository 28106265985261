import { Link } from 'gatsby';
import React from 'react';
import { Button } from '../../button/Button';
import { Container } from '../../container/Container';

export interface HeroSectionProps {
  className?: string;
  title: string | React.ReactNode;
  subtitle?: string;
  button?: {
    label: string;
    href: string;
    icon?: React.ReactNode;
  };
  image?: React.ReactNode;
  rightBgColor?: string;
}

export const HeroSection = (props: HeroSectionProps) => {
  const {
    className = '',
    title,
    subtitle,
    button,
    image,
    rightBgColor,
  } = props;
  return (
    <section
      className={`pt-36 h-full md:h-screen flex items-center relative overflow-hidden bg-background ${className}`}
    >
      <Container>
        <div className="flex flex-col md:flex-row  h-full">
          {rightBgColor && (
            <div
              className={`md:w-[12rem] lg:w-[20rem] xl:w-[26rem] 2xl:w-[36%] md:h-full absolute right-0 bottom-0 md:top-0 bg-${rightBgColor}`}
            />
          )}
          <div className="w-full md:w-1/2">
            <h1 className="text-4xl sm:text-5xl md:text-7xl leading-[116%] font-bold">
              {title}
            </h1>

            {subtitle && (
              <p className="font-light text-xl text-text opacity-[54%] mt-5">
                {subtitle}
              </p>
            )}

            {button && (
              <Link to={button.href} className="mt-14 block">
                <Button variant='primary' icon={button.icon}>
                  {button.label}
                </Button>
              </Link>
            )}
          </div>

          <div className="w-full md:w-1/2 flex ">
            <div className="relative  md:absolute mt-auto top-0 bottom-0 flex w-full 2xl:w-[70rem] xl:w-[50rem] lg:w-[50rem] md:w-[40rem] h-full items-center">
              {image}
            </div>
          </div>
        </div>
      </Container>
    </section>
  );
};
