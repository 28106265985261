import { StrapiSeo } from '@abclinic/ui';
import { graphql } from 'gatsby';
import React from 'react';
import { ContactPageContextProvider } from '../components/Contact/ContactPageContext/ContactPageContextProvider';
import { FaqsAndContactSection } from '../components/Contact/FaqsAndContactSection/FaqsAndContactSection';
import { ContactHeroSection } from '../components/Contact/Hero/ContactHeroSection';
import { LocationStreetview } from '../components/Contact/LocationStreetview/LocationStreetview';
import { Layout } from '../components/Layout/Layout';

export const ContactPage = ({ data }: any) => {
  return (
    <Layout>
      <StrapiSeo seo={data?.strapiContactPage?.seo} />
      <ContactPageContextProvider
        data={{
          ...data?.strapiContactPage,
          openingHours: data?.strapiGlobal?.openingHours,
        }}
      >
        <ContactHeroSection />
        <LocationStreetview />
        <FaqsAndContactSection />
      </ContactPageContextProvider>
    </Layout>
  );
};

export default ContactPage;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    strapiGlobal(locale: { eq: $language }) {
      openingHours {
        from
        to
        day
      }
    }
    strapiContactPage(locale: { eq: $language }) {
      seo {
        ...SeoFragment
      }
      hero {
        title
        titleBlue
        subtitle
      }
      faqSection {
        title
        subtitle
        items {
          question
          answer
          link {
            label
            href
          }
        }
      }
      contactFormHeader {
        title
        titleBlue
        subtitle
      }
    }
  }
`;
