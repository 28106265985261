import React from 'react';

export interface HeroTitleProps {
  title: string | React.ReactNode;
  titleBlue?: string | React.ReactNode;
  subtitle?: string | React.ReactNode;
}

export const HeroTitle = (props: HeroTitleProps) => {
  const { title, titleBlue, subtitle } = props;
  return (
    <div>
      <h1 className="text-titleText text-4xl sm:text-5xl md:text-7xl leading-[116%] font-bold">
        <span>{title}</span> <span className="text-primary">{titleBlue}</span>
      </h1>

      {subtitle && (
        <p className="font-light text-base lg:text-xl text-text opacity-[54%] mt-5 max-w-2xl">
          {subtitle}
        </p>
      )}
    </div>
  );
};
